import App from './App.vue';
import router from './router';
import store from './store';
import Vue from 'vue';
import vuetify from './plugins/vuetify';
import stripe from './plugins/stripe';
import Vuelidate from 'vuelidate';
import VueMask from 'v-mask';
import './mixins/globalMethods.js';

Vue.config.productionTip = false

Vue.use(Vuelidate);
Vue.use(VueMask);

new Vue({
    router,
    store,
    vuetify,
    stripe,
    render: h => h(App)
}).$mount('#app');


