import Vue from 'vue';
import { StripePlugin } from '@vue-stripe/vue-stripe';

const options = {
     pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
     locale: process.env.VUE_APP_STRIPE_LOCALE
};

Vue.use(StripePlugin, options);

export default StripePlugin;