import Vue from 'vue'

Vue.mixin({
    methods: {
        routerPush(step) {
            if (this.$route.query.constructor === Object && Object.keys(this.$route.query).length !== 0) {
                this.$router.push({
                    path: `/${step}/`,
                    query: this.$route.query
                });
            } else {
                this.$router.push(step);
            }
        },
        
        getPriceSplit(x){
            //get's price breakdown in an array with Currency, Units, cents, also checks if further thousands formatting is needed and includes it
            let array = [];

            let currency = (0).toLocaleString(
                'en-US',
                {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
                }
            ).replace(/\d/g, '').trim();
            array.push(currency);
            if(typeof x !== "string"){
                x = x.toFixed(2);               
            }else{
                x = parseFloat(x);
                x = x.toFixed(2);
            }

            let priceSplit = x.toString().split(".");
            
            if( priceSplit[0] !== 'undefined'){
                //checks is number will require comas 
                if(x > 999){
                    priceSplit[0] = priceSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
                array.push(priceSplit[0]);
            }
            if( priceSplit[1]){
                if (priceSplit[1].length < 2){// checkup for  JS removal of 0s in cents
                    array.push( "." +priceSplit[1]+ "0");
                }else{
                    array.push( "." +priceSplit[1]);
                }
            }
            else{ // in case it's empty JS will erase 00 
                array.push( ".00");
            }
            return array;
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        formatPercentage(value){
            return (value * 100).toFixed(0);
        },
        formatPrice(value) {
            //just adds currency and cents
            return "$ " + value.toFixed(2);
        },
        reformatPrice(value, skipCents) {
            //adds currency, cents and includes , in numbers that are > 999
            var computed = null;

            if(!skipCents){
                computed = value.toFixed(2);
            }
            else{
                computed = value;
            }
            // var computed = value.toFixed(2);
            return "$" + computed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        reformatCurrencyString(string, skipCents){
            // get's a value string that comes in format: $2500, returns to int form and calls reformat price to show it as required : $2,500.00 
            var intValue =  Number(string.replace(/[^0-9\.-]+/g,""));
            var reformatValue = this.reformatPrice(intValue, skipCents);
            return reformatValue;
        },
        goToElementAndFocus(elementid, ref){
            this.scrollTo(elementid);
            const element = this.$refs[ref];
            if (element) {
                element.focus();
            }else{
                document.getElementById(elementid).focus();
            }
        },
        scrollTo(id) {
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        },
        goToElementAndSetFocusCallback(elementid, callbackElementID){
            this.$store.commit("setDefinitionsButtonCallback", callbackElementID);
        },
        goToElementWithFocusCallback(){
            if( this.$store.state.definitionsButtonCallback ){
                //if element has a link id jump
                document.getElementById(this.$store.state.definitionsButtonCallback).focus();
                //clear after jump
                this.$store.commit("setDefinitionsButtonCallback", null);
            }
        },
        goToChildInputAndFocus(elementid){
            document.getElementById(elementid).querySelector(input).focus();
        }, 
        openDirectionCheckup(ddlWrapperID, requirementHeight){
            var originElement = document.getElementById(ddlWrapperID);
            var totalHeight = window.innerHeight;
            var offsetElement = originElement.getBoundingClientRect().top;
            var openUp = false;

            if( totalHeight - offsetElement < requirementHeight){
                openUp = true; 
            }
            return openUp;
        },
        focusButton(refName) {
            const interval = setInterval(()=>{
                if(this.$refs[refName]) {
                    this.$nextTick(()=>this.$refs[refName].$el.focus());
                    clearInterval(interval);
                }
            },50);
        },
        disablePopupsAffiliate(){
            // Disable for Affiliates with enrollment codes
            switch(this.$store.getters.enrollmentCode) {
                case '99-99-00-6555':
                    return true;
                case '99-99-00-9876': 
                    return true;
                case '99-99-99-0334': 
                    return true;
                case '99-99-99-0333':
                    return true;
                case '99-99-00-0002':
                    return true;
                default: 
                    return false
            }
        },
        getNumbertoAlphanumberic(numberString){
            var stringOutput = numberString;
            // if(numberString === '800-438-6388'){
            //     stringOutput = '800-GET-MET8';
            // }
            return stringOutput;
        },
        getPartnerContactInfo(){
            const defaultDynamicContent = this.$store.getters.getDefaultDynamicContent.DynamicJson;
            const dynamicContent = this.$store.getters.getDynamicContent.DynamicJson;

            let queryId = this.partnerId;
            queryId ? queryId = this.partnerId.toString() : queryId = this.$store.getters.partnerId;

          
            switch(this.$store.getters.enrollmentCode){     
            
                // Pet Insurer
                case '99-99-00-5056':
                    return '855-703-7387';
                // Fund.com - 
                case '99-99-00-6555': 
                    return '877-219-7161';
                //QuinStreet
                case '99-99-00-4477': 
                    return '877-219-9104';
                //All other affiliates -
                case '99-99-00-9876':
                    return '877-200-3498';
                //Good Boy Studios - 
                case '99-99-99-0333':
                    return '877-219-9220';
                //Hearst -
                case '99-99-99-0334':
                    return '877-219-9107';
                //Customer Advocate
                case '99-99-00-4516': 
                    return '855-212-7387';
            }

            if(dynamicContent && dynamicContent.companySupportNumber.trim() !== "") {
                return dynamicContent.companySupportNumber;
            }else {
                if(defaultDynamicContent && defaultDynamicContent.companySupportNumber.trim() !== "") return defaultDynamicContent.companySupportNumber;
            }
        },
        offerFamilyPlan(pets, isAgeRestrictionExempted) {
            const isMetGen = this.$store.getters.isMetGen;
            let offerFamily = true;

            if(isAgeRestrictionExempted) return true;

            if(isMetGen) {
                pets.forEach(pet => {
                    if(pet.species === 2 && pet.age >= 14 || pet.species === 1 && pet.age >= 12) {
                        offerFamily = false;
                        return;
                    }; // Cat 2 | Dog 1
                });
            }

            return offerFamily;
        },
        isPetUnderLimit(pet, isAgeRestrictionExempted) {
            if(isAgeRestrictionExempted) return true;

            return !(pet.species === 2 && pet.age >= 14 || pet.species === 1 && pet.age >= 12);
        },
        switchbackIndividual(offerFamily) {
            if (!offerFamily) {
                if(this.$store.state.isFamilyPlan) this.$store.commit('setSwitchBackIndividual', true);
                if(this.$store.state.isFamilyPlan) this.$store.commit("setSelectedPolicies", []);
                if(this.$store.state.isFamilyPlan) this.$store.commit('setPanelsExpanded', 0);
                this.$store.commit("setViewingFamilyPlan", false);
                this.$store.commit("setIsFamilyPlan", false);
            }
        },
        getDiscounstSelectedLength() {
            return this.$store.state.discounts.selection.length;
        },
        getDiscounstApplied() {
            const petLength = this.$store.state.pets.length;
            const numbers = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten"];
            const index = this.getDiscounstSelectedLength();
            const text = index === 1 ? "Discount Applied" : "Discounts Applied";

            if(index === 0 && petLength > 1) {
                return "Multi Pet Discount Applied";
            } else {
                return numbers[index] + " " + text
            }
        },
        getNumberToText(int){
            const numbers = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten"];
            return numbers[int];
        },
        getPetSpecie(specie) {
            if (specie === 1) return "Dog";
            if (specie === 2) return "Cat";
            return "Exotic";
        },
        getPetsSpecies(pets) {
            const species = pets.map(pet => {
                if (pet.species == 1) {
                    return 'Dog';
                } else if (pet.species == 2) {
                    return 'Cat';
                } else {
                    return 'Exotic';
                }
            }).join(", ");

            return species;
        },
        getStandardWellness(selectedPolicies, isFamilyPlan, pets) {
            if(isFamilyPlan) {
                return selectedPolicies[0].riderId === 164 ? 'Yes' : 'No';
            } else {
                const wellnessStatuses = pets.map((pet, index) => {
                    const policy = selectedPolicies[index];
                    return policy && policy.riderId === 164 ? 'Yes' : 'No';
                }).join(", ");
        
                return wellnessStatuses;
            }
        },
        focusErrorField() {
            setTimeout(() => {
                const failedValidation = document.querySelector(".v-input.error--text");
                if(failedValidation !== null){
                    if(failedValidation.classList.contains('hidden-input')){
                        failedValidation.parentNode.querySelector("button.v-btn").focus();
                    }else{
                        failedValidation.querySelector("input").focus();
                    }
                }
            }, 100);
        },
        getPetsNamesList() {
            const pets = this.$store.getters.pets;
            const isFamilyPlan = this.$store.getters.isFamilyPlan;
        
            const length = pets.length;
            if(length === 1) return pets[0].name;
            if(length === 2) return pets[0].name + " and " + pets[1].name;
            if(length === 3) return pets[0].name + ", " + pets[1].name + " and " +  pets[2].name;
            
        },
        getFamilyOrNameList() {
            const pets = this.$store.getters.pets;
            const isFamilyPlan = this.$store.getters.isFamilyPlan;
            if (isFamilyPlan) {
                return "your family"
            } else {
                const length = pets.length;
                if(length === 1) return pets[0].name;
                if(length === 2) return pets[0].name + " and " + pets[1].name;
                if(length === 3) return pets[0].name + ", " + pets[1].name + " and " +  pets[2].name;
            }
        },
        getFirstPetName() {
            const pets = this.$store.getters.pets;
            return pets[0].name;
        },
        getCurrentPetName() {
            const pets = this.$store.getters.pets;
            const currentPetIndex = this.$store.getters.currentPetIndex;
            return pets[currentPetIndex].name;
        },
        interpolateContent(self, content) {
            const matches = content.match(/{{\s*(.*?)\s*}}/g);
            
            if(matches) {
                matches.forEach(match => {
                    const propertyName = match.replace(/[{}]/g, '').trim();
                    
                    if (self.hasOwnProperty(propertyName) && typeof self[propertyName] === 'function') {
                        const replacement = self[propertyName](); 
                        content = content.replace(match, replacement); 
                    } 
                });
            }
            return content;
        },
        showDisclaimerCopyAffiliates() {
            switch(String(this.$store.getters.enrollmentCode)) {
                case '99-99-00-6555':
                    return true;
                case '99-99-00-9876': 
                    return true;
                case '99-99-99-0334': 
                    return true;
                default: 
                    return false;
            }
        },
        getFirstHalfArray(array){   
            const halfLength = Math.ceil(array.length / 2);
            return array.slice(0, halfLength);
        },
        getSecondHalfArray(array){
            const halfLength = Math.ceil(array.length / 2);
            return array.slice(halfLength, array.length);
        },
        delay(ms) {
            return new Promise(resolve => setTimeout(resolve,ms));
        },
        runInvoca() {
            let checkInvocaExist = setInterval(() => {
                if(typeof Invoca !== 'undefined'){
                    clearInterval(checkInvocaExist);
                    Invoca.PNAPI.run();
                }
            }, 100);
        },
        showWellnessSteteRestricted(partner, state) {
            const nyRestrictionsActive = this.$store.getters.getDefaultDynamicContent.DynamicJson.nyRestrictions;

            if(partner) return true;

            if(!nyRestrictionsActive) return true;
            
            if(state === 'NY') return false;

            return true;
        },
        stateRestricted(state) {
            const nyRestrictionsActive = this.$store.getters.getDefaultDynamicContent.DynamicJson.nyRestrictions;

            if(!nyRestrictionsActive) return false;

            return state === 'NY';
        },
        federalGroupFunctActive() {
            return this.$store.getters.getDefaultDynamicContent.DynamicJson?.federalFunctionality 
                ? this.$store.getters.getDefaultDynamicContent.DynamicJson.federalFunctionality 
                : false;
        },
        showFederalField(enrollmentCode) {
            const fedEnrollment = '99-99-00-0002';
            return this.federalGroupFunctActive() && fedEnrollment === enrollmentCode;
        },
        popullateFederalAnalyticProperty(ldo) {
            const civilian = this.$store.getters.getCivilian;
            const uniformedService = this.$store.getters.getUniformedService;

            if(civilian || uniformedService) {
                ldo.attributes.federalAffiliation = civilian !== null ? civilian : uniformedService;
                return ldo;
            } else {
                return ldo;
            }
        },
        getSidebarImage(image, isDefaultContent) {
            this.$store.dispatch('getSidebarImage', {
                payload: {
                    id: image.ConfigId,
                    imageName: image.Name,
                    seqNum: image.SeqNum
                }, 
                isDefaultContent: isDefaultContent
            });
        },
        setImageSource(defaultImage, dynamicImage, stepNum, commitName) {
            const defaultImageId = defaultImage ? defaultImage.ConfigId : null;
            const dynamicImageId = dynamicImage ? dynamicImage.ConfigId : null;

            const cachedDefaultImageUrl = defaultImageId ? sessionStorage.getItem(`step${stepNum}DefaultSideImgSource`) : null;
            const cachedImageUrl = dynamicImageId ? sessionStorage.getItem(`step${stepNum}SideImgSource`) : null;
  
            if (cachedImageUrl) {
                this.$store.commit(commitName, cachedImageUrl);
            } else if (cachedDefaultImageUrl && !dynamicImage) {
                this.$store.commit(commitName, cachedDefaultImageUrl);
            } else {
                if (dynamicImage) {
                    this.getSidebarImage(dynamicImage, false);
                } else if (defaultImage) {
                    this.getSidebarImage(defaultImage, true);
                }
            }
        },
        retrieveQuoteFunctActive() {
            return this.$store.getters.getDefaultDynamicContent.DynamicJson?.retrieveFunctionality 
                ? this.$store.getters.getDefaultDynamicContent.DynamicJson.retrieveFunctionality 
                : false;
        },
        ssnFunctActive() {
            const partner = this.$store.getters.partner;

            if(!partner.id) return false;

            if(partner.keyIdValidation === 0 && (partner.primaryKeyId === 'SSN' || partner.secondaryKeyId === 'SSN')) return true;

            if(partner.primaryKeyId === 'SSN' && partner.keyIdValidation === 1) return true; 

            if(partner.secondaryKeyId === 'SSN' && partner.keyIdValidation === 2) return true; 

            return false;
        },
        ssnPopupFunctionality() {
            const partner = this.$store.getters.partner;

            if(!partner.id) return false;

            if(partner?.keyIdValidationInd === 1) return true;

            return false;
        },
        clearPartnerObject() {
            const partner = {
                id: null,
                name: '',
                logo: null,
                enrollmentCode: '',
                supportPhone: '',
                isSsnRequired: false,
                isEmpIdRequired: false,
                planGroupId: '',
                showPayOption: '',
                payDeductDisclaimer: '',
                displayRoutine: false,
                isBiWeekly: false,
                bullets: null,
                billingOptions: '',
                effectiveDate: undefined,
                cdfGroupNumber: null,
                isAgeRestrictionExempted: false,
                imageWidth: 0,
                displayInQuoteFunnel: false,
                primaryKeyId: '',
                secondaryKeyId: '',
                keyIdValidation: null,
                keyIdValidationDesc: '',
                keyIdValidationInd: null
            };

            this.$store.commit("savePartner", partner);
            this.$store.commit("setPayOption", "creditCard");
        },
        btnCallNumber(phoneNumber){
            window.location.href = `tel:${phoneNumber}`;
        }
    },
    created(){
        document.addEventListener("keydown", event => {
            var elem = document.activeElement;
        })
    },
})