import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment'
import router from '../router';
import VueCookies from 'vue-cookies';
import { createStore } from 'vuex-extensions'; 
import Swal from 'sweetalert2';

Vue.use(VueCookies);
Vue.use(Vuex);

let apidomain = window.location.origin;

let previousCoverageName = "";

const http = axios.create({
    baseURL: `${apidomain}/QuoteFunnel/`
});

// vuex-persist syncs the store with sessionStorage
const vuexLocal = new VuexPersistence({
    key: 'qf',
    storage: window.sessionStorage
});

const state = {
    logoSource: "",
    PartnerLogoSource: "",
    PartnerLogoUrl: "#",
    pets: [],
    petSpecieList: [],
    petGenderList: [],
    riders: [],
    currentPetIndex: 0,
    quotes: [],
    breeds: {
        1: [],
        2: []
    },
    token: '',
    employeeToken: undefined,
    leadId: '',
    quoteId: undefined,
    lastStep: undefined,
    plans: {},
    enrollmentCode: '99-99-00-9998',
    clickId: '',
    source: undefined,
    noSms: false,
    cookiesOkay: false,
    isSms: false,
    emailQuote: false,
    isNationwide: false,
    policySold: false,
    quoteFunnelActivity: false,
    automaticCoverageRedirection: false,
    automaticCheckoutRedirection: false,
    automaticOwnerRedirection: false,
    automaticRedirection: true,
    colors: undefined,
    genders: undefined,
    species: undefined,
    weights: undefined,
    currentPet: {
        name: '',
        breed: '',
        mixed: undefined,
        breedName: '',
        age: '',
        weight: '',
        species: '',
        gender: '',
        providerId: null,
        provider: ''
    },
    currentPlans: [],
    currentFamilyPlans: [],
    limitOptions: [],
    deductibleOptions: [],
    reimbursementOptions: [],
    riderOptions: [],
    customizedPlanValues: {
        limit: 0,
        deductible: 0,
        reimbursement: 0,
        rider: 0
    },
    owner: {
        zip: '',
        pn: '',
        email: '',
        state: '',
        city: '',
        pn: '',
        address: {
            billing: {},
            physical: {}
        }
    },
    modalKey: 0,
    modal: {
        show: false,
        return: undefined,
        init: undefined,
        submit: undefined,
        cancel: undefined,
        content: {
            heading: '',
            body: '',
            buttons: {
                submit: '',
                cancel: ''
            }
        }
    },
    paymentFrequency: 'Monthly',
    effectiveDate: undefined,
    discounts: {
        selection: [],
        options: {}
    },
    confirmation: {
        totals: {
            subtotal: '',
            tax: '',
            total: ''
        },
        discounts: [],
        pets: [],
        policies: [],
        expiry: ''
    },
    policies: [],
    paymentProcessing: false,
    steps: {
        pet: { active: true, complete: false },
        pricing: { active: false, complete: false },
        billing: { active: false, complete: false },
        payment: { active: false, complete: false },
        summary: { active: false, complete: false }
    },
    payOption: 'creditCard',
    partnerId: undefined,
    partner: {
        id: null,
        name: '',
        logo: null,
        enrollmentCode: '',
        supportPhone: '',
        isSsnRequired: false,
        isEmpIdRequired: false,
        planGroupId: '',
        showPayOption: '',
        payDeductDisclaimer: '',
        displayRoutine: false,
        isBiWeekly: false,
        bullets: null,
        billingOptions: '',
        effectiveDate: undefined,
        cdfGroupNumber: null,
        isAgeRestrictionExempted: false,
        imageWidth: 0,
        displayInQuoteFunnel: false,
        primaryKeyId: '',
        secondaryKeyId: '',
        keyIdValidation: null,
        keyIdValidationDesc: '',
        keyIdValidationInd: null
    },
    states: [
        { id: 1, abbreviation: "KY" },
        { id: 2, abbreviation: "IN" },
        { id: 3, abbreviation: "WV" },
        { id: 4, abbreviation: "OH" },
        { id: 5, abbreviation: "CA" },
        { id: 6, abbreviation: "PA" },
        { id: 7, abbreviation: "AL" },
        { id: 8, abbreviation: "AK" },
        { id: 9, abbreviation: "AZ" },
        { id: 10, abbreviation: "AR" },
        { id: 11, abbreviation: "CO" },
        { id: 12, abbreviation: "CT" },
        { id: 13, abbreviation: "DC" },
        { id: 14, abbreviation: "FL" },
        { id: 15, abbreviation: "GA" },
        { id: 16, abbreviation: "HI" },
        { id: 17, abbreviation: "ID" },
        { id: 18, abbreviation: "IA" },
        { id: 19, abbreviation: "KS" },
        { id: 20, abbreviation: "ME" },
        { id: 21, abbreviation: "MD" },
        { id: 24, abbreviation: "MS" },
        { id: 25, abbreviation: "MO" },
        { id: 26, abbreviation: "MT" },
        { id: 27, abbreviation: "NE" },
        { id: 28, abbreviation: "NV" },
        { id: 29, abbreviation: "NH" },
        { id: 30, abbreviation: "NJ" },
        { id: 31, abbreviation: "NM" },
        { id: 32, abbreviation: "NY" },
        { id: 34, abbreviation: "ND" },
        { id: 35, abbreviation: "OK" },
        { id: 36, abbreviation: "RI" },
        { id: 37, abbreviation: "SC" },
        { id: 38, abbreviation: "SD" },
        { id: 39, abbreviation: "TN" },
        { id: 40, abbreviation: "TX" },
        { id: 41, abbreviation: "UT" },
        { id: 42, abbreviation: "VT" },
        { id: 44, abbreviation: "WA" },
        { id: 45, abbreviation: "WI" },
        { id: 46, abbreviation: "WY" },
        { id: 47, abbreviation: "DE" },
        { id: 48, abbreviation: "MI" },
        { id: 49, abbreviation: "OR" },
        { id: 50, abbreviation: "IL" },
        { id: 51, abbreviation: "LA" },
        { id: 52, abbreviation: "MN" },
        { id: 53, abbreviation: "MA" },
        { id: 54, abbreviation: "NC" },
        { id: 55, abbreviation: "VA" }
    ],
    checkoutContact: {
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        zip: '',
        pn: '',
        ssn: '',
        employeeId: '',
        customerId: 0,
        sameAddress: "true",
        state: '',
        billing: {
            firstName: '',
            lastName: '',
            address1: '',
            address2: '',
            zip: '',
        }
    },
    selectedPolicies: [],
    isFamilyPlan: false,
    viewingFamilyPlan: false,
    showRoutineCare: false,
    loadingNewPrices: false,
    pageName: "pet",
    accidentDate: null,
    illnessDate: null,
    maxPetView: 3,
    isMetGen: false,
    reportNumber: null,
    gsspLeadId: null,
    hideGSSPSSN: false,
    hideGSSPEmployeeId: false,
    limitsWithFilings: [],
    currentPage: 'Pet Info',
    hasSentQuoteStart: false,
    futureEnrollmentDate: false,
    tpe: "",
    existingCustomerSearch: {
        firstName: "",
        lastName: "",
        mobilePhone: "",
        emailAddress: ""
    },
    isExistingCustomer: false,
    currentQuoteFunnel: null,
    mcid: null,
    campaignId: null,
    loading: false,
    dotLoader: false,
    isEmily: false,
    firedPricingTag: false,
    firedContactTag: false, 
    definitionsButtonCallback: null,
    quoteSaved: false,
    saveQuoteDialog: false,
    saveQuotePhone: '',
    multiPetDiscounts:  [],
    utmSource:  null,
    utmMedium:  null,
    petsWithWellness: [0, 0, 0],
    familyWithWellness: 0,
    dicountsUpdated: false,
    project: undefined,
    paymentRequestList: null,
    quote: null,
    petsQuotes: null,
    employeeSearchActive: false,
    employeeSearchCount: 0,
    cbsEmployeeID: "",
    searchEmployeeAgain: false,
    isPetProductEligible: false,
    IAICPayOptionSetUp: false,
    emptySsn: false,
    defaultDynamicContent: {
        DynamicJson: null
    },
    dynamicContent: {
        DynamicJson: null,
        SsnCapture: null
    },
    step1SideImgSource: "",
    step2SideImgSource: "",
    step3SideImgSource: "",
    step4SideImgSource: "",
    dynamicUrl: null,
    civilian: null,
    uniformedService: null,
    groupDiscounts: [],
    dynamicContentKey: null,
    reloadLogo: false,
    panelsExpanded: [],
    switchBackIndividual: false,
};

const getDefaultState = () => {
    return {
        items: [],
        status: 'empty'
    }
}

const getters = {
    currentPet(state) { return state.currentPet; },
    currentPetIndex(state) { return state.currentPetIndex; },
    pets(state) { return state.pets; },
    riders(state) { return state.riders; },
    weights(state) { return state.weights; },
    breeds(state){return state.breeds},
    colors(state){return state.colors},
    genders(state){return state.genders},
    species(state){return state.species},
    breed: (state) => (id) => { return state.breeds[id]; },
    steps(state) { return state.steps; },
    owner(state) { return state.owner; },
    modalKey(state) {return state.modalKey },
    modal(state) { return state.modal; },
    plans(state) { return state.plans; },
    paymentFrequency(state) { return state.paymentFrequency; },
    colors(state) { return state.colors; },
    policies(state) { return state.policies; },
    confirmation(state) { return state.confirmation; },
    paymentProcessing(state) { return state.paymentProcessing; },
    checkoutContact(state) { return state.checkoutContact; },
    partner(state) { return state.partner; },
    payOption(state) { return state.payOption; },
    effectiveDate(state) { return state.effectiveDate; },
    clickId(state) { return state.clickId; },
    enrollmentCode(state) { return state.enrollmentCode; },
    partnerId(state) { return state.partnerId; },
    employeeToken(state) { return state.employeeToken; },
    isNationwide(state) { return state.isNationwide; },
    token(state) { return state.token; },
    currentPlans(state) { return state.currentPlans; },
    currentFamilyPlans(state) { return state.currentFamilyPlans; },
    availableDiscounts(state) { return state.discounts.options},
    selectedDiscounts(state) { return state.discounts.selection },
    customizedPlanValues(state) { return state.customizedPlanValues },
    limitOptions(state) { return state.limitOptions },
    deductibleOptions(state) { return state.deductibleOptions },
    reimbursementOptions(state) { return state.reimbursementOptions },
    riderOptions(state) { return state.riderOptions },
    isFamilyPlan(state) { return state.isFamilyPlan },
    selectedPolicies(state) { return state.selectedPolicies },
    currentPetIndex(state) { return state.currentPetIndex },
    viewingFamilyPlan(state) { return state.viewingFamilyPlan },
    showRoutineCare(state) { return state.showRoutineCare },
    loadingNewPrices(state) { return state.loadingNewPrices },
    pageName(state) { return state.pageName },
    accidentDate(state) { return state.accidentDate },
    illnessDate(state) { return state.illnessDate },
    maxPetView(state) { return state.maxPetView },
    petSpecieList(state) { return state.petSpecieList },
    petGenderList(state) { return state.petGenderList },
    isMetGen(state) { return state.isMetGen },
    hideGSSPSSN(state) { return state.hideGSSPSSN },
    hideGSSPEmployeeId(state) { return state.hideGSSPEmployeeId },
    logoSource(state) { return state.logoSource },
    PartnerLogoSource(state) { return state.PartnerLogoSource },
    PartnerLogoUrl(state) { return state.PartnerLogoUrl },
    futureEnrollmentDate(state) { return state.futureEnrollmentDate },
    isExistingCustomer(state) { return state.isExistingCustomer },
    getExistingCustomerSearch(state) { return state.existingCustomerSearch },
    leadId(state) { return state.leadId },
    quoteFunnelActivity(state) { return state.quoteFunnelActivity },
    currentQuoteFunnel(state) { return state.currentQuoteFunnel },
    loading(state) { return state.loading },
    reannounceDotLoader(state) { return state.reannounceDotLoader },
    isDotLoading(state) { return state.dotLoader },
    isEmily(state) { return state.isEmily },
    firedPricingTag(state) { return state.firedPricingTag },
    firedContactTag(state) { return state.firedContactTag },
    multiPetDiscounts(state) {  return state.multiPetDiscounts},
    quoteSaved(state) {  return state.quoteSaved},
    saveQuoteDialog(state) {  return state.saveQuoteDialog},
    saveQuotePhone(state) { return state.saveQuotePhone},
    petsWithWellness(state) { return state.petsWithWellness },
    getProject(state) { return state.project },
    getPaymentRequestList(state) { return state.paymentRequestList },
    getQuote(state) { return state.quote },
    getPetsQuotes(state) { return state.petsQuotes },
    getEmployeeSearchCount(state) { return state.employeeSearchCount },
    getIAICPayOptionSetUp(state) { return state.IAICPayOptionSetUp },
    isEmptySsn(state) { return state.emptySsn },
    getDefaultDynamicContent(state) { return state.defaultDynamicContent; },
    getDynamicContent(state) { return state.dynamicContent; },
    getStep1SideImgSource(state) { return state.step1SideImgSource },
    getStep2SideImgSource(state) { return state.step2SideImgSource },
    getStep3SideImgSource(state) { return state.step3SideImgSource },
    getStep4SideImgSource(state) { return state.step4SideImgSource },
    isEmptySsn(state) { return state.emptySsn },
    getCurrentPage(state){ return state.currentPage },
    getCivilian(state){ return state.civilian },
    getUniformedService(state){ return state.uniformedService },
    getDynamicContentKey(state){ return state.dynamicContentKey },
    getReloadLogo(state){ return state.reloadLogo },
    getPanelsExpanded(state) { return state.panelsExpanded },
    getSwitchBackIndividual(state) { return state.switchBackIndividual }
}

const mutations = {
    updateOwner(state, value){
        state.owner = {...state.owner, ...value};
    },
    setOwnerPhone(state,value){
        state.owner.pn = value;
    },
    setOwnerEmail(state,value){
        state.owner.email = value;
    },
    updateCurrentPet(state, value){
        if (!state.pets[state.currentPetIndex]){
            state.pets[state.currentPetIndex] = {};
        }
        Vue.set(state.pets, state.currentPetIndex, { ...state.pets[state.currentPetIndex], ...value });
    },
    saveEnrollmentCode(state, enrollmentCode) {
        state.enrollmentCode = enrollmentCode;
    },
    async saveSource(state, source) {

        let response = await http.get('source?source=' + source);

        state.source = response.data;
    },
    saveApiToken(state, token) {
        state.token = token;
    },

    saveColors(state, colors) {
        state.colors = colors;
    },
    saveCheckOutContact(state, checkOutContact) {
        state.checkoutContact = checkOutContact;
    },
    async saveLeadId(state, leadId) {
        state.leadId = leadId;
    },
    saveEmployeeToken(state, employeeToken) {
        state.employeeToken = employeeToken;
    },
    saveEffectiveDate(state, effectiveDateString) {
        state.effectiveDate = new Date(effectiveDateString);
    },
    saveEffectiveDay(state, effectiveDayInt) {
        if (state.effectiveDate !== undefined) {
            let efDate = moment(state.effectiveDate).toISOString();
            if (moment().isBefore(moment(efDate))) {
                state.effectiveDay = state.effectiveDate
            } else {
                state.effectiveDay = moment().add(1, 'M').set("date", effectiveDayInt).toDate();
            }
        } else {
            state.effectiveDay = moment().add(1, 'M').set("date", effectiveDayInt).toDate();
        }
    },
    savePartnerId(state, partnerId) {
        state.partnerId = partnerId;
    },
    savePartner(state, partner) {
        state.partner = partner;
    },
    saveClickId(state, clickId) {
        state.clickId = clickId;
    },
    updatePet(state, value) {
        state.currentPet = { ...state.currentPet, ...value };
    },
    savePet(state, index) {
        if (index || index === 0) {
            state.pets[index] = Object.assign(state.pets[index], state.currentPet)
        } else {
            var current = Object.assign({}, state.currentPet);
            state.pets.push(current);
        }
    },
    savePetFromData(state, { data }) {
        state.pets.push(Object.assign({}, data));
    },
    setStepComplete(state, step) {
        state.steps[step].complete = true;
    },
    setStepActive(state, step) {
        for (var key in state.steps) {
            state.steps[key].active = false;
        }
        state.steps[step].active = true;
    },
    setPayOption(state, payOption) {
        state.payOption = payOption;
    },
    setMaxPetView(state, petViewValue) {
        state.maxPetView = petViewValue;
    },
    setPaymentFrequency(state, paymentFrequency) {
        state.paymentFrequency = paymentFrequency;
    },
    clearCurrentPet(state) {
        state.currentPet = {
            name: '',
            breed: '',
            mixed: undefined,
            breedName: '',
            age: '',
            weight: '',
            species: '',
            gender: ''
        }
    },
    clearModal(state) {
        state.modal.show = false;
        state.modal.content.header = '';
        state.modal.content.body = '';
        state.modal.content.buttons.submit = '';
        state.modal.content.buttons.cancel = '';
        state.modal.submit = undefined;
    },
    setPolicy(state, { key, petKey, plan, policyNumber }) {
        state.policies[key].policyNumber = policyNumber;
        state.policies[key].plan = Object.assign(state.policies[key].plan, state.plans[petKey])
        state.policies[key].pet = Object.assign(state.policies[key].pet, state.pets[petKey])
    },
    clearPolicies(state) {
        state.policies = [];
    },
    saveConfirmation(state) {
        state.confirmation.expiry = new Date().getTime();
        // discounts
        sessionStorage.setItem('confirmationSummary', JSON.stringify(state.confirmation))
    },
    getConfirmation(state) {
        return sessionStorage.getItem('confirmationSummary');
    },
    saveDiscount(state, { selection, options }) {
        state.discounts.selection = selection;
        Vue.set(state.discounts, 'options', options);
    },
    saveDiscount(state,selection) {
        state.discounts.selection = selection;
        state.dicountsUpdated = true;
    },
    setConfirmationProp(state, { prop, val }) {
        try {

            state.confirmation[prop] = (typeof val === "string") ? state[val] : val;
        } catch (e) {

            console.error(e);
        }
    },
    updatePaymentProcessing(state, value) {

        state.paymentProcessing = value;
    },
    setCurrentPetIndex(state,value){
        state.currentPetIndex = value;
    },
    setCurrentPlans(state,value){
        state.currentPlans = value;
    },
    setCurrentFamilyPlans(state,value){
        state.currentFamilyPlans = value;
    },
    setCustomizedLimit(state,value){
        state.customizedPlanValues.limit = value;
    },
    setCustomizedDeductible(state,value){
        state.customizedPlanValues.deductible = value;
    },
    setCustomizedReimbursement(state,value){
        state.customizedPlanValues.reimbursement = value;
    },
    setCustomizedRider(state,value){
        state.customizedPlanValues.rider = value;
    },
    setLimitOptions(state,value){
        state.limitOptions = value;
    },
    setDeductibleOptions(state,value){
        state.deductibleOptions = value;
    },
    setReimbursementOptions(state,value){
        state.reimbursementOptions = value;
    },
    setRiderOptions(state,value){
        state.riderOptions = value;
    },
    setWeightsOptions(state, value) {
        state.weights = value;
    },
    setSelectedPolicies(state,value){
        state.selectedPolicies = value;
    },
    setIsFamilyPlan(state,value){
        state.isFamilyPlan = value;
    },
    setViewingFamilyPlan(state,value){
        state.viewingFamilyPlan = value;
    },
    setShowRoutineCare(state,value){
        state.showRoutineCare = value;
    },
    setLoadingNewPrices(state,value){
        state.loadingNewPrices = value;
    },
    setCurrentPet(state,value){
        state.currentPet = value;
    },
    setPageName(state,value){
        state.pageName = value;
    },
    savePets(state,value){
        state.pets = value;
    },
    setPetSpecieList(state, value) {
        state.petSpecieList = value;
    },
    setPetGenderList(state, value) {
        state.petGenderList = value;
    },
    saveProviderId(state, { petIndex, providerId, previousCoverage }) {
        state.pets[petIndex].providerId = providerId;
        state.pets[petIndex].provider = previousCoverage;
        previousCoverageName = previousCoverage;
    },
    saveReportNumber(state,value){
        state.reportNumber = value;
    },
    saveGSSPLeadId(state,value){
        state.gsspLeadId = value;
    },
    saveSpecies(state,value){
        state.currentPet.species = value;
    },
    saveGender(state,value){
        state.currentPet.gender = value;
    },
    savePetName(state,value){
        state.currentPet.name = value;
    },
    setLogoSource(state, value) {
        state.logoSource = value;
    },
    setPartnerLogoSource(state, value) {
        state.PartnerLogoSource = value;
    },
    setPartnerLogoUrl(state, value) {
        state.PartnerLogoUrl = value;
    },
    setCurrentPage(state,value){
        state.currentPage = value;
    },
    saveCheckoutPhoneNumber(state,value){
        state.checkoutContact.pn = value;
    },
    saveTPE(state,value){
        state.tpe = value;
    },
    setExistingCustomerSearch(state,value){
        state.existingCustomerSearch = value;
    },
    setIsExistingCustomer(state,value){
        state.isExistingCustomer = value;
    },
    setQuoteFunnelActivity(state,value){
        state.quoteFunnelActivity = value;
    },
    setMCID(state,value){
        state.mcid = value;
    },
    setCampaignId(state,value){
        state.campaignId = value;
    },
    setNoSms(state,value){
        state.noSms = value;
    },
    setLoading(state,value){
        state.loading = value;
    },
    setReannounceDotLoader(state, value) {
        state.reannounceDotLoader = value;
    },
    setDotLoader(state,value){
        state.dotLoader = value;
    },
    setFiredPricingTag(state,value){
        state.firedPricingTag = value;
    },
    setFiredContactTag(state,value){
        state.firedContactTag = value;
    },
    setDefinitionsButtonCallback(state, value){
        state.definitionsButtonCallback = value;
    },
    setQuoteSaved(state, value) {  
        state.quoteSaved = value;
    },
    setSaveQuoteDialog(state, value) {  
        state.saveQuoteDialog = value;
    },
    setSaveQuotePhone(state, value) {
        state.saveQuotePhone = value;
    },
    setMultiPetDiscounts(state,value){
        let discounts = [];
        value.forEach(petQuote => {
            for(const quote of petQuote.fastQuotes){
                discounts.push(quote.totalMultiPolicyDiscount);
                break;
            }

        });
        state.multiPetDiscounts = discounts;
    },
    saveUTMSource(state,value){
        state.utmSource = value;
    },
    saveUTMMedium(state,value){
        state.utmMedium =  value;
    },
    setPaymentRequestList(state,value) {
        state.paymentRequestList = value;
    },
    setQuote(state,value) { 
        state.quote = value;
    },
    setPetsQuotes(state,value) { 
        state.petsQuotes = value;
    },
    updateSelectedPolicies(state, { index, planData }) {
        Vue.set(state.selectedPolicies, index, planData);
    },
    addEmptyPolicies(state) { 
        state.selectedPolicies.push({
            petArrayIndex: -1,
            planDetails: {
                monthlyPremium: 0
            }
        });
    },
    setEmployeeSearchActive(state, value) {
        state.employeeSearchActive = value;
    },
    setEmployeeSearchCount(state,value) {
        state.employeeSearchCount = value;
    },
    setCBSEmployeeID(state, value) {
        state.cbsEmployeeID = value;
    },
    setSearchEmployeeAgain(state, value) {
        state.searchEmployeeAgain = value;
    },
    setIsPetProductEligible(state, value) {
        state.isPetProductEligible = value;
    },
    setIAICPayOptionSetUp(state, value) {
        state.IAICPayOptionSetUp = value;
    },
    setDefaultDynamicContent(state, value) {
        state.defaultDynamicContent = value;
    },
    setDynamicContent(state, value) {
        state.dynamicContent = value;
    },
    clearDynamicContent(state) {
        state.dynamicContent = {
            Name: '',
            PartnerId: 0,
            EnrollmentCode: '',
            IsPublished: false,
            Url: '',
            UserId: 0,
            ReportNumber: '',
            DynamicJson: null,
            ZipCode: '',
            StateId: 0,
            ImageName: '',
            SsnCapture: null
        }
    },
    setStep1SideImgSource(state, value) {
        state.step1SideImgSource = value;
    },
    setStep2SideImgSource(state, value) {
        state.step2SideImgSource = value;
    },
    setStep3SideImgSource(state, value) {
        state.step3SideImgSource = value;
    },
    setStep4SideImgSource(state, value) {
        state.step4SideImgSource = value;
    },
    saveCivilian(state,value){
        state.civilian =  value;
    },
    saveUniformedService(state,value){
        state.uniformedService =  value;
    },
    setDynamicContentKey(state,value){
        state.dynamicContentKey = value;
    },
    setReloadLogo(state,value){
        state.reloadLogo = value;
    },
    setPanelsExpanded(state, value){
        state.panelsExpanded = value;
    },
    setSwitchBackIndividual(state, value){
        state.switchBackIndividual = value;
    }
}

const actions = {
    resetQFState() {
        store.reset();
    },
    async getStepsConfig({state}, {quoteFunnelId: quoteFunnelId, quoteFunnelType: quoteFunnelType, quoteFunnelIsActive: quoteFunnelIsActive}) {
        let response;
        try {
            response = await http.get(`GetQuoteFunnel/${quoteFunnelId}/${quoteFunnelType}?isActive=${quoteFunnelIsActive}`);

            if (response.data == null || response.data == "") {
                if (response.status == 200 && quoteFunnelId != 'pet') {
                    try {
                        let response = await http.get('GetQuoteFunnel/pet/URL');
                        state.currentQuoteFunnel = response.data.Data;
                        return response.data.Data;
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
            state.currentQuoteFunnel = response.data.Data;
            return response.data.Data;
        } catch (e) {
            if (response.status == 404 && quoteFunnelId != 'pet') {
                try {
                    let response = await http.get('GetQuoteFunnel/pet/URL');
                    state.currentQuoteFunnel = response.data.Data;
                    return response.data.Data;
                } catch (e) {
                    // TODO: HANDLE ERROR
                    console.log(e);
                }
            }else{
                // TODO: HANDLE ERROR
                console.log(e);
            }
        }
    },
    async getSpecies({ state }) {
        try {
            let response = await http.get('Species');
            if (state.species !== undefined) {
                return state.species;
            }
            state.species = response.data;
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
    async getWeights({state}) {
        try {

            let response = await http.get('Weights');
            if (state.weights !== undefined) {
                return state.weights;
            }
            state.weights = response.data;

            state.weights.forEach(weight => {
                weight.description = weight.label + " - " + weight.description
            });
            
            return state.weights;
        } catch (e) {
            console.log(e);
        }
    },
    async getGenders({state}) {
        try {
            if (state.genders !== undefined) {
                return state.genders;
            }
            let response = await http.get('Genders');
            state.genders = response.data;
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
    async getColors({state}) {
        try {
            if (state.colors !== undefined) {
                return state.colors;
            }
            let response = await http.get('Colors');
            state.colors = response.data;
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
    async getAges({state}) {
        try {

            if (state.ages !== undefined) {
                return state.ages;
            }
            let response = await http.get('Ages');
            state.ages = response.data;
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
    async getPlansByState({ state }) {
        try {
            let response = await http.get('/Plans/GetByState/' + state.owner.state + '/1')
            state.plans = response.data;
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
    async getPlansByZipAndEnrollmentCode({ state }) {
        try {
            let response = await http.get('/Plans/GetByZipAndEnrollment/' + state.owner.zip + '/' + state.enrollmentCode)
            //state.plans = response.data;
            return response.data.Data;
        } catch (e) {
            console.log(e);
        }
    },
    async getPlanOptions({ state }, payload) {
        let payLoadType = typeof payload;
        try {
            let data;
            if (payLoadType === 'number') {
                data = {
                    'StateAbbreviation': state.owner.state,
                    'SpeciesId': state.pets[payload].species,
                    "GenderId": state.pets[payload].gender,
                    'BreedId': state.pets[payload].breed,
                    'Age': state.pets[payload].age
                }
                if (state.pets[payload].mixed) {
                    data['WeightId'] = state.pets[payload].weight;
                }
            } else {
                data = {
                    'StateAbbreviation': state.owner.state,
                    'SpeciesId': payload.species,
                    "GenderId": payload.gender,
                    'BreedId': payload.breed,
                    'Age': payload.age
                }
                if (payload.mixed) {
                    data['WeightId'] = payload.weight;
                }
            }
            if (state.discounts.selection.length) {
                data["DiscountIds"] = state.discounts.selection;
            }
            let response = await http.post('Quote/PlanOptions', qs.stringify(data));
            return response.data;
        } catch (e) {
            console.error(e);
        }
    },
    async getFastQuotes({ state }) {
        let totalMultiPolicyDiscount =  state.isFamilyPlan ? 1 : state.multiPetDiscounts[state.currentPetIndex];

        let data = {
            "emailAddress": state.owner.email,
            "state": state.owner.state,
            "zip": state.owner.zip,
            "enrollmentCode": state.enrollmentCode,
            "discountIds": state.discounts.selection,
            "pets":[],
            "partnerName": state.partner.name,
            "partnerBillingOptions": state.partner.billingOptions,
            "payOption": state.payOption,
            "effectiveDay": moment().add(1, 'days').format('YYYY-MM-DD'),
            "isExistingCustomer": state.isExistingCustomer,
            "RiderId": state.familyWithWellness,
            "TotalMultiPolicyDiscount":  totalMultiPolicyDiscount,
            "PetsRiderIds": state.petsWithWellness,
            "IsAgeRestrictionExempted": state.partner.isAgeRestrictionExempted
        }

        if (state.effectiveDate !== undefined) {
            data.effectiveDay = moment(state.effectiveDate).format('YYYY-MM-DD');
        }

        state.pets.forEach(p => {
            let pet = {
                "petName": p.name,
                "speciesId": parseInt(p.species),
                "breedId": parseInt(p.breed),
                "weight": p.weight,
                "age": parseInt(p.age),
                "gender": parseInt(p.gender),
                "isMixedBreed": p.mixed
            }
            data.pets.push(pet);
        });
        try {
            let response = await http.post('GetPrices', data)
            .catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error Fetching Quotes',
                    text: 'An error occurred while fetching your quote. Please try again later. If this problem persist, please contact support.',
                    confirmationButton: "Okay"
                }).then(() => {
                    window.location.href = 'https://metlifepetinsurance.com';
                });
            });
            state.limitsWithFilings = response.data.limitsWithFilings;
            return response.data;
        } catch (e) {
            //TODO: DISPLAY ERROR
            console.error(e);
        }
    },
    async getRoutineCareFastQuotes({state},riderId) {
        let totalMultiPolicyDiscount =  state.isFamilyPlan ? 1 : state.multiPetDiscounts[state.currentPetIndex];

        let data = {
            "emailAddress": state.owner.email,
            "state": state.owner.state,
            "zip": state.owner.zip,
            "enrollmentCode": state.enrollmentCode,
            "discountIds": state.discounts.selection,
            "pets":[],
            "partnerName": state.partner.name,
            "partnerBillingOptions": state.partner.billingOptions,
            "payOption": state.payOption,
            "effectiveDay": moment().add(1, 'days').format('YYYY-MM-DD'),
            "riderId": riderId,
            "TotalMultiPolicyDiscount":  totalMultiPolicyDiscount,
            "PetsRiderIds": state.petsWithWellness,
            "IsAgeRestrictionExempted": state.partner.isAgeRestrictionExempted
        }

        if(state.isExistingCustomer){
            data["TotalMultiPolicyDiscount"] =  3;
        }

        if (state.effectiveDate !== undefined) {
            data.effectiveDay = moment(state.effectiveDate).format('YYYY-MM-DD');
        }

        if(state.isFamilyPlan){
            state.pets.forEach(p => {
                let pet = {
                    "petName": p.name,
                    "speciesId": parseInt(p.species),
                    "breedId": parseInt(p.breed),
                    "weight": p.weight,
                    "age": parseInt(p.age),
                    "gender": parseInt(p.gender),
                    "isMixedBreed": p.mixed
                }
                data.pets.push(pet);
            });
        }else{
            let p = state.currentPet;
            let pet = {
                "petName": p.name,
                "speciesId": parseInt(p.species),
                "breedId": parseInt(p.breed),
                "weight": p.weight,
                "age": parseInt(p.age),
                "gender": parseInt(p.gender),
                "isMixedBreed": p.mixed
            }
            data.pets.push(pet);
        }
        try {
            let response = await http.post('GetRoutineCarePrices', data);

            let selectedPolicies = state.selectedPolicies;

            let planIndex = state.selectedPolicies.find(p => p.petArrayIndex === state.currentPetIndex).planId;

            let selectedCurrentPlan = response.data.find(x => x.planId === planIndex);

            selectedPolicies[state.currentPetIndex].limit = selectedCurrentPlan.limit;
            selectedPolicies[state.currentPetIndex].deductible = selectedCurrentPlan.deductible;
            selectedPolicies[state.currentPetIndex].reimbursement = selectedCurrentPlan.reimbursements;
            selectedPolicies[state.currentPetIndex].riderId = riderId;
            selectedPolicies[state.currentPetIndex].planDetails = selectedCurrentPlan;

            Vue.set(state, 'selectedPolicies', selectedPolicies);
            Vue.set(state, 'currentPlans', response.data);

            if(!state.isFamilyPlan) state.petsWithWellness[state.currentPetIndex] = riderId;
            if(state.isFamilyPlan) state.familyWithWellness = riderId;

            return response.data;
        } catch (e) {
            //TODO: DISPLAY ERROR
            console.error(e);
        }
    },
    async getCustomizedFastQuote({state},{customizedPlan, petIndex}){
        let planId = state.limitsWithFilings.find(p => p.limit === customizedPlan.planOptions.limit).planId;
        let totalMultiPolicyDiscount =  state.isFamilyPlan ? 1 : state.multiPetDiscounts[petIndex];

        let data = {
            "emailAddress": state.owner.email,
            "address": {
                "streetAddress": "",
                "streetAddress2": "",
                "cityName": "",
                "stateAbbreviation": state.owner.state,
                "zipCode": state.owner.zip
            },
            "pets": [],
            "paymentFrequency": "Monthly",
            "legacyPlanId": planId,
            "discountIds": state.discounts.selection,
            "enrollmentCode": state.enrollmentCode,
            "termLimit": customizedPlan.planOptions.limit,
            "deductible": customizedPlan.planOptions.deductible,
            "reimbursement": customizedPlan.planOptions.reimbursements,
            "riderId":  customizedPlan.planOptions.rider.id,
            "TotalMultiPolicyDiscount":  totalMultiPolicyDiscount,
        };

        if(state.isExistingCustomer){
             data["TotalMultiPolicyDiscount"] = 3;
        }

        if ((state.partner.name != null && (state.partner.billingOptions === "payrollDeduct" || (state.partner.billingOptions === "both" && state.payOption === null))) || state.payOption === "payrollDeduct") {
            data['isPayrollDeduct'] = true;
        } else {
            data['isPayrollDeduct'] = false;
        }

        if (state.effectiveDate !== undefined) {
            data['EffectiveDate'] = state.effectiveDate;
        }

        customizedPlan.pets.forEach(p => {
            let pet = {
                "petName": p.name,
                "speciesId": parseInt(p.species),
                "breedId": parseInt(p.breed),
                "weight": p.weight,
                "age": parseInt(p.age),
                "gender": parseInt(p.gender),
                "isMixedBreed": p.mixed
            }
            data.pets.push(pet);
        });

        try {
            let response = await http.post('FastQuote', data);
            return response.data;
        } catch (e) {
            //TODO: DISPLAY ERROR
            console.error(e);
        }
    },
    async getDiscountsByZip({ state }) {
        try {
            let effectiveDate = moment().add(1, 'days').format('YYYY-MM-DD');
            if (state.effectiveDate !== undefined) {
                effectiveDate = moment(state.effectiveDate).format('YYYY-MM-DD');
            }
            let response = await http.get(`Discounts?enrollmentcode=${state.enrollmentCode}&zip=${state.owner.zip}&effectiveDate=${effectiveDate}`);
            let data = response.data;

            let discountIds = [];
            for (var i = 0; i < data.length; i++) {
                discountIds.push(data[i].discountId);
            }

            var selectedDiscounts = [];
            var availableDiscounts = [];
            var selectDiscount = [];

            selectDiscount = state.groupDiscounts.length > 0 ? discountIds.filter(item => state.groupDiscounts.includes(item)) : [];

            for (var i = 0; i < data.length; i++) {
                if ((state.employeeToken || state.enrollmentCode == '99-99-00-9022') && data[i].name === "Animal Care Discount") {
                    continue;
                }
                if (data[i].description.indexOf("First Payment") > -1 ||
                    data[i].description.indexOf("Employee/Member Group") > -1 ||
                    data[i].description.indexOf("Premium discount") > -1 ||
                    state.discounts.selection.indexOf(data[i].discountId.toString()) > -1
                ) {
                    selectedDiscounts.push(data[i].discountId);
                }
                if (data[i].drivenByEnrollmentCode && !selectedDiscounts.includes(data[i].discountId)) {
                    selectedDiscounts.push(data[i].discountId);
                }
                if(state.uniformedService !== null && data[i].description.indexOf("Serving Military") > -1) {
                    selectedDiscounts.push(data[i].discountId);
                }
                availableDiscounts.push(data[i]);
            }
            var result = selectDiscount.length > 0 ? selectDiscount.filter(item => !selectedDiscounts.includes(item)) : [];
            if (result.length > 0) {
                for (var d = 0; d < result.length; d++) {
                    if (!selectedDiscounts.includes(result[d])) {
                        selectedDiscounts.push(result[d]);
                    }
                 }
            }

            if (!state.dicountsUpdated) Vue.set(state.discounts, 'selection', selectedDiscounts);
            Vue.set(state.discounts, 'options', availableDiscounts);
            return availableDiscounts;
        } catch (e) {
            console.log(e);
        }
    },
    async getLeadId({ state, dispatch }) {
        try {
            let apiResponse = await http.get('Lead/' + state.leadId);
            let response = apiResponse.data;

            //response.weigth = 1;
            state.isEmily = true;
            state.owner.zip = response.zipCode;
            dispatch("getUserState");
            state.checkoutContact.zip = response.zipCode;
            state.checkoutContact.pn = response.phoneNumber;
            state.checkoutContact.firstName = response.firstName == "unknown" ? null : response.firstName;
            state.checkoutContact.lastName = response.lastName == "unknown" ? null : response.lastName;
            state.checkoutContact.address1 = response.streetAddress1;
            state.checkoutContact.address2 = response.streetAddress2;
            state.checkoutContact.city = response.city;
            state.checkoutContact.state = response.state;
            state.source = response.source;
            state.isSms = response.isSms;
            state.emailQuote = response.emailQuote;
            if(response.email !== null) state.owner.email = response.email.trim();
            state.reportNumber = response.reportNumber;
            state.tpe = response.tpeSource;
            state.mcid = response.mcid;
            state.campaignId = response.campaignId;
            state.utmSource =  response.utmSource;
            state.utmMedium =  response.utmMedium;
            state.civilian = response.civilian;
            state.uniformedService = response.uniformedService;

            if (response.groupId !== null) {
                state.partnerId = response.groupId;
                dispatch("getPartnerInfoByPartnerId");
            }

            if (response.reportNumber !== null && response.reportNumber !== 0) {
                state.reportNumber = response.reportNumber;
            }

            let leadQuotes = await http.get(`LeadQuotes/${state.leadId}`);
            if(leadQuotes.data !== null && leadQuotes.data.length > 0){
                if(typeof leadQuotes.data[0].enrollmentCode !== 'undefined'){
                    if(leadQuotes.data[0].enrollmentCode !== '99-99-00-9998'){
                        state.enrollmentCode = leadQuotes.data[0].enrollmentCode;
                    }
                }
            }

            apiResponse = await http.get('LeadCustomerActivity/' + state.leadId);
            response = apiResponse.data[0];

            state.lastStep = response.activity;

            if (response.activity !== 'pet') {

                apiResponse = await http.get('LeadPets/' + state.leadId);
                response = apiResponse.data;

                response.forEach((pet, index) => {
                    dispatch("getBreed", { breedId: pet.speciesId });

                    if(pet.bday !== undefined) {
                        const duration = moment.duration(new moment().diff(new moment(pet.bday)));
                        pet.age = parseInt(duration.asYears());
                    }

                    Vue.set(state.pets, index, {
                        id: pet.id,
                        name: pet.name,
                        breed: pet.breedId,
                        species: pet.speciesId,
                        gender: pet.gender == "M" ? 1 : 2,
                        age: pet.age,
                        weight: pet.weightId,
                        mixed: pet.breedId == 54 || pet.breedId == 2
                    });
                });

                dispatch("getWeights");
            }

            return response;
        } catch (e) {
            console.log(e);
        }
    },
    async getLeadByEmail({ state }, { email, token }) {
        try {
            let apiResponse = await http.post('Lead', { email }, {
                headers: { 'Authorization': `${token}` }
            });
            let response = apiResponse.data;
    
            return response;
        } catch (e) {
            console.log(e);
        }
    },
    async getCustomerInfoByToken({ state, dispatch }) {
        try {
            state.emptySsn = false;
            
            var data = { token: encodeURIComponent(state.employeeToken) }
            let apiResponse = await http.post('Customer/Details', data);
            let response = apiResponse.data;

            if(response.customer.zipCode !== null && response.customer.zipCode !== ""){
                state.owner.zip = response.customer.zipCode;
                dispatch("getUserState");
            }

            state.owner.state = response.customer.state;
            state.owner.email = response.customer.emailAddress.trim();
            state.checkoutContact.zip = response.customer.zipCode.substring(0, 5);
            state.checkoutContact.pn = response.customer.homePhone;
            state.checkoutContact.firstName = response.customer.firstName == "unknown" ? null : response.customer.firstName;
            state.checkoutContact.lastName = response.customer.lastName;
            state.checkoutContact.address1 = response.customer.streetAddress;
            if(response.customer.streetAddress2 !== null){
                state.checkoutContact.address2 = response.customer.streetAddress2;
            }
            state.checkoutContact.city = response.customer.cityName;
            state.checkoutContact.state = response.customer.state;
            !response.customer.ssn ? state.emptySsn = true : state.emptySsn = false;
            state.checkoutContact.ssn = response.customer.ssn;
            if (state.checkoutContact.ssn !== "") {
                state.checkoutContact.ssn = state.checkoutContact.ssn.replace(/\s/g, "");
            }
            state.checkoutContact.employeeId = response.customer.employeeId;
            state.checkoutContact.customerId = response.customer.id;
            
            if(response.partner !== null){
                state.partner.name = response.partner.name;
                state.partner.id = response.partner.id;
                dispatch('setPartnerLogoByPartnerId', response.partner.id);
                dispatch("getStepsConfig", {
                    quoteFunnelId: response.partner.id,
                    quoteFunnelType: "PartnerId",
                    quoteFunnelIsActive: true
                });
                state.partner.logo = response.partner.logo;
                state.partner.enrollmentCode = response.partner.enrollmentCode;
                state.enrollmentCode = response.enrollmentCode;
                state.partner.supportPhone = response.partner.supportPhone;
                state.partner.isSsnRequired = response.partner.isSsnRequired && state.checkoutContact.ssn == "";
                state.partner.isEmpIdRequired = response.partner.isEmpIdRequired && state.checkoutContact.employeeId == "";
                state.partner.planGroupId = response.partner.planGroupId;
                state.partner.showPayOption = response.partner.showPayOption;
                state.partner.effectiveDate = response.partner.effectiveDate;
                state.partner.enrollmentDate = response.partner.enrollmentDate;
                if (response.partner.enrollmentDate !== null && response.partner.enrollmentDate !== "") {
                    if (moment(response.partner.enrollmentDate).isAfter(moment())) {
                        state.futureEnrollmentDate = true;
                    }
                }
                if (response.partner.effectiveDate !== null && response.partner.effectiveDate !== "") {
                    if (response.partner.enrollmentDate === null) {
                        var sevenBefore = moment(response.partner.effectiveDate).subtract(7, 'd');
                        if (sevenBefore.isAfter(moment())) {
                            state.futureEnrollmentDate = true;
                        }
                    }
                    state.effectiveDate = moment(response.partner.effectiveDate).format("MM/DD/YYYY");
                    dispatch("getDate");
                    dispatch("getDatePlus14");
                }

                digitalData.user.attributes.enrollmentID = response.partner.enrollmentCode;
                digitalData.user.attributes.groupID = response.partner.id;
                digitalData.user.attributes.groupName = response.partner.name;
                digitalData.user.attributes.userType = 'GROUP';


                if (response.partner.billingOptions == 1) {
                    state.partner.billingOptions = 'directBill';
                    state.payOption = 'creditCard';
                } else if (response.partner.billingOptions == 2) {
                    state.partner.billingOptions = 'payrollDeduct';
                    state.payOption = 'payrollDeduct';
                } else if (response.partner.billingOptions == 3) {
                    state.partner.billingOptions = 'both';
                }

                state.partner.billingOption = response.partner.showPayOption;
                state.partner.payDeductDisclaimer = response.partner.payDeductDisclaimer;
                state.partner.displayRoutine = response.partner.displayRoutine;
                state.partner.isBiWeekly = response.partner.isBiWeekly;
                state.partner.bullets = response.partner.bullets;
            }

            return response;
        } catch (e) {
            console.log(e);
        }
    },

    async getGSSPLeadId({state, dispatch}){
        let apiResponse = await http.get(`MetLife/GSSP/GetLeadById/${state.gsspLeadId}`);
        let partner = null;
        let response = apiResponse.data.item;

        if(response.extension.cdfGroupNumber !== ""){
            state.partnerId = apiResponse.data.item.extension.cdfGroupNumber;
            dispatch("getStepsConfig", {
                quoteFunnelId: apiResponse.data.item.extension.cdfGroupNumber,
                quoteFunnelType: "CDFCustomerNumber",
                quoteFunnelIsActive: true
            });
            let partnerResponse = await dispatch("getPartnerInfoByCdfCustomerNumber");
            partner = partnerResponse;

            if(response.addresses !== null){
                if(typeof response.addresses[0].postalCode !== 'undefinied' && response.addresses[0].postalCode !== null 
                && response.addresses[0].postalCode !== ""){
                    state.owner.zip = response.addresses[0].postalCode;
                    dispatch("getUserState");
                }
                state.owner.state = response.addresses[0].stateCode;
                state.owner.pn = response.phoneNumbers[0].number;
                state.checkoutContact.zip =  response.addresses[0].postalCode.substring(0, 5);
                state.checkoutContact.address1 = response.addresses[0].addressLine1;
                state.checkoutContact.address2 = response.addresses[0].addressLine2;
                state.checkoutContact.city = response.addresses[0].city;
                state.checkoutContact.state = response.addresses[0].stateCode;
            }

            // if(partner !== ""){
            //     state.owner.workEmailAddress = response.emails[0].address.trim();
            // }else{
            //     state.owner.email = response.emails[0].address.trim();
            // }
            state.owner.email = response.emails[0]?.address?.trim();
            state.checkoutContact.pn = response.phoneNumbers[0].number;
            state.checkoutContact.firstName = response.name.firstName;
            state.checkoutContact.lastName = response.name.lastName;
            
            if (response.extension.governmentIssuedIds.length > 0) {
                if (response.extension.governmentIssuedIds[0].typeCode === "SSN") {
                    state.checkoutContact.ssn = response.extension.governmentIssuedIds[0].value;
                    state.hideGSSPSSN = true;
                }
            }

            if (response.extension.uisEmployeeNumber !== "") {
                state.checkoutContact.employeeId = response.extension.uisEmployeeNumber;
                state.hideGSSPEmployeeId = true;
            }

            return partner;
        }else{
            if(response.addresses !== null){
                if(typeof response.addresses[0].postalCode !== 'undefinied' && response.addresses[0].postalCode !== null 
                && response.addresses[0].postalCode !== ""){
                    state.owner.zip = response.addresses[0].postalCode;
                    dispatch("getUserState");
                }
                state.owner.state = response.addresses[0].stateCode;
                state.owner.pn = response.phoneNumbers[0].number;
                state.checkoutContact.zip =  response.addresses[0].postalCode.substring(0, 5);
                state.checkoutContact.address1 = response.addresses[0].addressLine1;
                state.checkoutContact.address2 = response.addresses[0].addressLine2;
                state.checkoutContact.city = response.addresses[0].city;
                state.checkoutContact.state = response.addresses[0].stateCode;
            }

            state.owner.pn = response.phoneNumbers[0].number;
            state.owner.email = response.emails[0].address.trim();
            state.checkoutContact.pn = response.phoneNumbers[0].number;
            state.checkoutContact.firstName = response.name.firstName;
            state.checkoutContact.lastName = response.name.lastName;

            if (response.extension.governmentIssuedIds.length > 0) {
                if (response.extension.governmentIssuedIds[0].typeCode === "SSN") {
                    state.checkoutContact.ssn = response.extension.governmentIssuedIds[0].value;
                    state.hideGSSPSSN = true;
                }
            }

            if (response.extension.uisEmployeeNumber !== "") {
                state.checkoutContact.employeeId = response.extension.uisEmployeeNumber;
                state.hideGSSPEmployeeId = true;
            }
            
            return partner;
        }
    },

    async getListOfPartners({ state }) {
        try {

            let apiResponse = await http.get('PartnerList');

            let partners = apiResponse.data;

            return partners;

        } catch (e) {
            console.log(e);
        }
    },

    async getPartnerInfoByCdfCustomerNumber({ state, commit, dispatch }) {
        try {
            let apiResponse = await http.get(`EmployeeBenefits/GroupConfig/${state.partnerId}`).catch((err) => {
                if (err.response.status === 404) {
                    sessionStorage.clear();
                    store.reset();
                    window.location.replace("https://www.metlifepetinsurance.com/group-not-found/");
                }
            });

            let response = apiResponse.data;
            if(response !== ""){
                if(!response.isActive) {
                    sessionStorage.clear();
                    store.reset();
                    window.location.replace("https://www.metlifepetinsurance.com/group-not-found/");
                }
                state.partner.name = response.name;
                state.partner.id = response.id;
                state.partner.cdfGroupNumber = response.cdfCustomerNumber;
                dispatch('setPartnerLogoByPartnerId', response.id);
                state.partner.logo = response.logo;
                state.partner.enrollmentCode = response.enrollmentCode;
                state.enrollmentCode = response.enrollmentCode;
                if(response.enrollmentCode === '99-99-00-9966'){
                    commit("setPartnerLogoSource",require('../assets/images/Zappos-Logo.png'));
                }
                state.partner.isSsnRequired = response.isSsnRequired;
                state.partner.isEmpIdRequired = response.isEmpIdRequired;
                state.partner.effectiveDate = response.effectiveDate;
                state.partner.enrollmentDate = response.enrollmentDate;
                if (response.enrollmentDate !== null && response.enrollmentDate !== "") {
                    if (moment(response.enrollmentDate).isAfter(moment())) {
                        state.futureEnrollmentDate = true;
                        response.futureEnrollmentDate = true;
                    }
                }

                if (response.partnerDiscounts && response.partnerDiscounts.length > 0) {
                    for (var i = 0; i < response.partnerDiscounts.length; i++) {
                        state.groupDiscounts.push(response.partnerDiscounts[i].discountId);
                    }
                }
                if (response.effectiveDate !== null && response.effectiveDate !== "") {
                    if (response.enrollmentDate === null) {
                        var sevenBefore = moment(response.effectiveDate).subtract(7, 'd');
                        if (sevenBefore.isAfter(moment())) {
                            state.futureEnrollmentDate = true;
                            response.futureEnrollmentDate = true;
                        }
                    }
                    state.effectiveDate = moment(response.effectiveDate).format("MM/DD/YYYY");
                    dispatch("getDate");
                    dispatch("getDatePlus14");
                }

                digitalData.user.attributes.enrollmentID = response.enrollmentCode;
                digitalData.user.attributes.groupID = response.id;
                digitalData.user.attributes.groupName = response.name;
                digitalData.user.attributes.userType = 'GROUP';

                if (response.acceptedPaymentMethods == 1) {
                    state.partner.billingOptions = 'directBill';
                    state.payOption = 'creditCard';
                } else if (response.acceptedPaymentMethods == 2) {
                    state.partner.billingOptions = 'payrollDeduct';
                    state.payOption = 'payrollDeduct';
                } else if (response.acceptedPaymentMethods == 3) {
                    state.partner.billingOptions = 'both';
                }
                state.partner.bullets = response.bullets;
                state.employeeToken = "nonSSOtoken";
                state.partner.isAgeRestrictionExempted = response.isAgeRestrictionExempted;

                state.partner.primaryKeyId = response.primaryKeyId;
                state.partner.secondaryKeyId = response.secondaryKeyId;
                state.partner.keyIdValidation = response.keyIdValidation;
                state.partner.keyIdValidationDesc = response.keyIdValidationDesc;
                state.partner.keyIdValidationInd = response.keyIdValidationInd;

                //Dynamic Logo Load
                state.partner.imageWidth = response.imageWidth;
                state.partner.displayInQuoteFunnel = response.displayInQuoteFunnel;
            }else{
                state.partnerId = undefined;
            }

            return response;
        } catch (e) {
            console.log(e);
        }
    },

    async getPartnerInfoByPartnerId({ state, dispatch, commit }) {
        try {

            let apiResponse = await http.get(`Partner/${state.partnerId}`).catch((err) => {
                if (err.response.status === 404) {
                    // window.location.replace("https://www.metlifepetinsurance.com/group-not-found/");
                }
            });

            let response = apiResponse.data;

            state.partner.name = response.name;
            state.partner.id = response.id;
            state.partner.cdfGroupNumber = response.cdfCustomerNumber;
            state.partnerId = response.cdfCustomerNumber;
            state.partner.logo = response.logo;
            state.partner.enrollmentCode = response.enrollmentCode;
            if(response.enrollmentCode === '99-99-00-9966'){
                commit("setPartnerLogoSource",require('../assets/images/Zappos-Logo.png'));
            }
            state.enrollmentCode = response.enrollmentCode;
            state.partner.isSsnRequired = response.isSsnRequired;
            state.partner.isEmpIdRequired = response.isEmpIdRequired;
            state.partner.effectiveDate = response.effectiveDate;
            state.partner.enrollmentDate = response.enrollmentDate;
            if (response.enrollmentDate !== null && response.enrollmentDate !== "") {
                if (moment(response.enrollmentDate).isAfter(moment())) {
                    state.futureEnrollmentDate = true;
                }
            }
            if (response.partnerDiscounts && response.partnerDiscounts.length > 0) {
                for (var i = 0; i < response.partnerDiscounts.length; i++) {
                    state.groupDiscounts.push(response.partnerDiscounts[i].discountId);
                }
            }
            if (response.effectiveDate !== null && response.effectiveDate !== "") {
                if (response.enrollmentDate === null) {
                    var sevenBefore = moment(response.effectiveDate).subtract(7, 'd');
                    if (sevenBefore.isAfter(moment())) {
                        state.futureEnrollmentDate = true;
                    }
                }
                state.effectiveDate = moment(response.effectiveDate).format("MM/DD/YYYY");
                dispatch("getDate");
                dispatch("getDatePlus14");
            }

            digitalData.user.attributes.enrollmentID = response.enrollmentCode;
            digitalData.user.attributes.groupID = response.id;
            digitalData.user.attributes.groupName = response.name;
            digitalData.user.attributes.userType = 'GROUP';

            if (response.acceptedPaymentMethods == 1) {
                state.partner.billingOptions = 'directBill';
                state.payOption = 'creditCard';
            } else if (response.acceptedPaymentMethods == 2) {
                state.partner.billingOptions = 'payrollDeduct';
                state.payOption = 'payrollDeduct';
            } else if (response.acceptedPaymentMethods == 3) {
                state.partner.billingOptions = 'both';
            }
            state.partner.bullets = response.bullets;
            state.employeeToken = "nonSSOtoken";
            state.partner.isAgeRestrictionExempted = response.isAgeRestrictionExempted;

            state.partner.primaryKeyId = response.primaryKeyId;
            state.partner.secondaryKeyId = response.secondaryKeyId;
            state.partner.keyIdValidation = response.keyIdValidation;
            state.partner.keyIdValidationDesc = response.keyIdValidationDesc;
            state.partner.keyIdValidationInd = response.keyIdValidationInd;
            
            //Dynamic Logo Load
            state.partner.imageWidth = response.imageWidth;
            state.partner.displayInQuoteFunnel = response.displayInQuoteFunnel;
            
            return response;
        } catch (e) {
            console.log(e);
        }
    },

    async getEmployerInfoByPartnerId({ state, dispatch, commit }, partnerId) {
        try {

            let apiResponse = await http.get(`Partner/${partnerId}`).catch((err) => {
                if (err.response.status === 404) {
                    // window.location.replace("https://www.metlifepetinsurance.com/group-not-found/");
                }
            });

            return apiResponse.data;
        } catch (e) {
            console.log(e);
        }
    },

    async getBreed({ state }, { breedId }) {
        if (state.breeds[breedId] && state.breeds[breedId].length) {
            return state.breeds[breedId];
        } else {
            try {

                let response = await http.get('Breed/' + breedId);
                state.breeds[breedId] = response.data;
                return response.data;
            } catch (e) {
                console.log(e);
            }
        }
    },
    async getBreedSpotlight(context) {
        try {
            //let response = await axios.get(`${(window && window.location.hostname === 'localhost' ? 'https://petfirstnew.wpengine.com/' : '')}/wp-json/wp/v2/pets?_embed`);
            //let response = await axios.get(`${('https://petfirstnew.wpengine.com/')}/wp-json/wp/v2/pets?_embed`);
            let response = await axios.get(window && window.location.hostname === 'localhost' ? 'http://localhost:4000/pets.json' : 'https://petfirstnew.wpengine.com//wp-json/wp/v2/pets?_embed');
            return response;
        } catch (e) {
            console.log(e);
        }
    },
    async getWhatsCovered(context) {
        try {
            //let response = await axios.get(`${(window && window.location.hostname === 'localhost' ? 'https://petfirstnew.wpengine.com/' : '')}/wp-json/petfirst/v1/1661?fields=whats_covered|whats_not_covered`);
            let response = await axios.get(`${('https://petfirstnew.wpengine.com/')}/wp-json/petfirst/v1/1661?fields=whats_covered|whats_not_covered`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
    async getUserState({ state }) {

        var stateFound = false;
        var subpremise = '';
        let response = await http.get('Address?zipCode=' + state.owner.zip);
        if (response.status === 200) {
            stateFound = true;
            subpremise = response.data.zipCode;
            state.owner.state = response.data.stateAbbreviation;
            state.owner.city = response.data.cityName;
        }

        //set metgen if the stated is allowed for Met Gen
        let statesArray = [ 
            'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO',
            'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID',
            'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'MA', 
            'ME', 'MD', 'MI', 'MS', 'MO', 'MN', 'MT', 
            'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 
            'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 
            'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 
            'WV', 'WI', 'WY' 
        ];

        if (statesArray.indexOf(state.owner.state) !== -1) {
          state.isMetGen = true;
        } else {
          state.isMetGen = false;
        }

        if ((subpremise && (subpremise === state.owner.zip)) || !stateFound) {
            return undefined;
        } else {
            return state.owner.state;
        }


    },
    async checkValidUSZipCode({ state }, zip) {
        let response = await http.get('Address?zipCode=' + zip);
        if (response.status === 200) {
            return true;
        }
        return false;
    },

    async getAddressInfoFromZip(context, { zip }) {
        let response = await http.get('Address?zipCode=' + zip);
        let returnObj = {
            city: response.data.cityName,
            state: response.data.stateAbbreviation,
            country: 'US' // default value 
        }
        return returnObj;
    },
    async getQuote({ state, dispatch }, { pets, plan, address, totalMultiPolicyDiscount }) {
        
        let data = {
            "EmailAddress": state.owner.email,
            "FirstName": state.owner.firstName,
            "LastName": state.owner.lastName,
            "Address": address,
            "Pets": pets,
            "PaymentFrequency": state.paymentFrequency,
            "LegacyPlanId": plan.LegacyPlanId || 0,
            "TermLimit": plan.TermLimit,
            "Deductible": plan.Deductible,
            "Reimbursement": plan.Reimbursement,
            "RiderId": plan.RiderId,
            "TotalMultiPolicyDiscount": totalMultiPolicyDiscount,
            "IsPayrollDeduct": false
        }

        // If existing customer set TotalMultiPolicyDiscount = 3 
        // for Multi-Pet discount
        if(state.isExistingCustomer){
             data["TotalMultiPolicyDiscount"] = 3;
        }

        if (state.effectiveDate !== undefined) {
            data.EffectiveDate = moment(state.effectiveDate).format('YYYY-MM-DD');
        }

        if (state.discounts.selection.length) {
            data["DiscountIds"] = state.discounts.selection;
        }
        if (state.enrollmentCode != '') {
            data['EnrollmentCode'] = state.enrollmentCode;
        }

        if (state.partner.name != "" && state.payOption != 'creditCard' && state.payOption != 'directBill') {
            data['IsPayrollDeduct'] = true
        }

        try {
            let response = await http.post('Quote', data);
            var dataResponse = response.data;
            Vue.set(state.policies, plan.PetIndex, {
                plan: dataResponse
            });

            dispatch("createOrUpdateLeadQuote");
            return dataResponse;
        } catch (e) {
            console.log(e);
        }
    },
    async saveQuotes({ state }) {
        var plansPromises = [];
        var plansLength = Object.keys(state.plans).length;

        for (var i = 0; i < plansLength; i++) {
            (function (j) {
                plansPromises.push(() => {
                    let data = {
                        "QuoteId": state.plans[j].QuoteId,
                        "EmailAddress": state.owner.email,
                        "StateAbbreviation": state.owner.state,
                        "PetName": state.pets[j].name,
                        "SpeciesId": state.pets[j].species,
                        "GenderId": state.pets[j].gender,
                        "BreedId": state.pets[j].breed,
                        "Age": state.pets[j].age,
                        "LegacyPlanId": state.plans[j].LegacyPlanId,
                        "FilingId": state.plans[j].FilingId,
                        "MostPopular": false,
                        "TermLimit": state.plans[j].TermLimit,
                        "Deductible": state.plans[j].Deductible,
                        "Reimbursement": state.plans[j].Reimbursement,
                        "RoutineNone": state.plans[j].RoutineNone,
                        "Routine125": state.plans[j].Routine125,
                        "Routine250": state.plans[j].Routine250,
                        "Routine400": state.plans[j].Routine400,
                        "PaymentFrequency": "Monthly",
                        "DiscountIds": state.discounts.selection
                    }
                    if (state.pets[j].weight) {
                        data['Weight'] = Number(state.pets[j].weight);
                    }
                    return http.post('Quote/SavePlanOptionsQuote', qs.stringify(data))
                })
            }(i));
        }
        var allgood = true;
        var resp = await Promise.all(plansPromises.map(cb => cb()))
            .catch(e => {
                console.log(e);
                throw e;
            })
            .then(responses => {
                let SavedQuoteIds = '';
                for (var i = 0; i < responses.length; i++) {
                    allgood = (responses[i].status === 200) ? true : false;
                    if (allgood) {
                        SavedQuoteIds += `quoteid=${responses[i].data.SavedQuoteId}`;
                    }
                }
                if (allgood) {
                    return SavedQuoteIds;
                } else {
                    return false;
                }
            })
        return resp;
    },
    async getSavedQuote({ state }, { email, quoteid }) {
        try {
            let response = await http.get(`Quote/Saved/${email}/${quoteid}`);
            return response.data;
        } catch (e) {
            console.error(e);
        }
    },
    async submitPurchase({ state, dispatch }, { contact, pets, petIndex, token, paymentFrequency, paymentMethod, totalMultiPolicyDiscount }) {
        let today = new Date();
        for (var i = 0; i < pets.length; i++) {
            pets[i].PreviousCoverageName = previousCoverageName;
        }

        if(!contact.sameAddress) {
            const billingStateInfo = await dispatch("getUserBillingState", contact.billing.zip);

            if(billingStateInfo) {
                contact.billing.city = billingStateInfo.cityName
                contact.billing.state = billingStateInfo.stateAbbreviation
            }
        }

        let data = {
            "Customer": {
                "CustomerId": state.checkoutContact.customerId,
                "FirstName": contact.firstName,
                "LastName": contact.lastName,
                "HomePhone": contact.pn.replace(/\D+/g, ""),
                "EmailAddress": state.owner.email,
                "Address": {
                    "StreetAddress": contact.address1,
                    "StreetAddress2": contact.address2,
                    "CityName": contact.city,
                    "StateAbbreviation": contact.state,
                    "ZipCode": contact.zip
                }
            },
            "BillingCustomer": {
                "FirstName": contact.sameAddress ? contact.firstName : contact.billing.firstName,
                "LastName": contact.sameAddress ? contact.lastName : contact.billing.lastName,
                "Address": {
                    "StreetAddress": contact.sameAddress ? contact.address1 : contact.billing.address1,
                    "StreetAddress2": contact.sameAddress ? contact.address2 : contact.billing.address2,
                    "CityName": contact.sameAddress ? contact.city : contact.billing.city,
                    "StateAbbreviation": contact.sameAddress ? contact.state : contact.billing.state,
                    "ZipCode": contact.sameAddress ? contact.zip : contact.billing.zip
                }

            },
            "Pets": pets,
            "Coverage": {
                "TermLimit": state.policies[petIndex].plan.termLimit,
                "Deductible": state.policies[petIndex].plan.deductible,
                "Reimbursement": state.policies[petIndex].plan.reimbursement,
                "RiderId": state.policies[petIndex].plan.riderId
            },
            "LegacyPlanId": state.policies[petIndex].plan.legacyPlanId || 1131,
            "FilingId": state.policies[petIndex].plan.filingId || 53,

            "PaymentFrequency": state.paymentFrequency,
            "PaymentMethod": paymentMethod,
            "PolicyBuildMethod": "NewPolicy",
            "TotalMultiPolicyDiscount": totalMultiPolicyDiscount
        }
    
        // If existing customer set TotalMultiPolicyDiscount = 3 
        // for Multi-Pet discount
        if(state.isExistingCustomer){
            data['TotalMultiPolicyDiscount'] = 3;
        }

        if (state.enrollmentCode != '') {
            data['EnrollmentCode'] = state.enrollmentCode;
        }
        if (state.discounts.selection.length) {
            data["DiscountIds"] = state.discounts.selection;
        }

        if (state.partner.id !== null) {
            data["PartnerId"] = state.partner.id;
        }

        let response = undefined;

        if (state.employeeToken != undefined) {
            data["Employer"] = "employer";
            data["UISEmployeeID"] = contact.employeeId;
            data["SSN"] = contact.ssn;
            data["GroupId"] = state.partner.id;
        }

        if (state.enrollmentCode === '99-99-00-0002') data["SSN"] = contact.ssn;

        if(state.reportNumber !== null){
            data['ReportNumber'] = state.reportNumber;
        }

        if (state.partner.billingOptions == 'payrollDeduct' || state.payOption == 'payrollDeduct') {
            if (state.employeeSearchActive) {
                data["CBSEmployeeID"] = state.cbsEmployeeID;
                data["SearchEmployeeAgain"] = state.searchEmployeeAgain;
                data["IsPetProductEligible"] = state.isPetProductEligible;
            }
            data["SaleDate"] = moment(today).format('YYYY-MM-DD[T]HH:mm:ss.SSS')
            response = await http.post('PurchaseVisFin', data);
        } else {
            data["StripeToken"] = token.id;
            data["PaymentMethod"] = 2;
            response = await http.post('Purchase', data);
        }

        state.confirmation.pets[petIndex] = Object.assign({}, state.pets[petIndex]);
        state.confirmation.policies[petIndex] = Object.assign({
            PolicyNumber: response.data.policyNumber,
            PolicyId: response.data.policyId,
            CustomerId: response.data.customerId,
            ExpirationDate: response.data.expirationDate,
            AccidentDate: response.data.accidentDate,
            IllnessDate: response.data.illnessDate
        }, state.policies[petIndex].plan);

        return response.data;

    },
    async loadSavedQuotes({ state, dispatch, commit }, { quotes, multiple }) {
        if (multiple) {
            state.owner.email = quotes[0]['EmailAddress'];
            state.owner.state = quotes[0]['Address']['StateAbbreviation'];
            state.owner.zip = quotes[0]['Address']['ZipCode'];
            state.owner.discounts.selection = quotes[0]['DiscountIds'];
            quotes.forEach(loadQuoteData)
        } else {
            state.owner.email = quotes['EmailAddress'];
            state.owner.state = quotes['Address']['StateAbbreviation'];
            state.owner.zip = quotes['Address']['ZipCode'];
            state.discounts.selection = quotes['DiscountIds'];
            loadQuoteData(quotes, 0);
        }
        state.steps['pet'].complete = true;
        state.steps['owner'].complete = true;
        state.steps['pet'].active = false;
        state.steps['coverage'].active = false;
        router.push('coverage');

        function alreadyEntered(petname) {
            let matches = false;
            for (var i = 0; i < state.pets.length; i++) {
                if (state.pets[i].name === petname) {
                    matches = true;
                }
            }
            return matches;
        }
        function loadQuoteData(item, index) {

            if (!alreadyEntered(item['PetName'])) {
                Vue.set(state.plans, index, {
                    "TermLimit": item["TermLimit"],
                    "Deductible": item["Deductible"],
                    "Reimbursement": item["Reimbursement"],
                    "RoutineNone": item["RoutineNone"],
                    "Routine125": item["Routine125"],
                    "Routine250": item["Routine250"],
                    "Routine400": item["Routine400"],
                    "LegacyPlanId": item["LegacyPlanId"],
                    "FilingId": item["FilingId"]
                })
                state.pets.push({
                    age: item['Age'],
                    breed: item['BreedId'],
                    breedName: "Afghan Hound",
                    mixed: ((item['BreedId'] == '54') && (item['SpeciesId'] == '1')) ? 'true' : 'false',
                    name: item['PetName'],
                    species: item['SpeciesId'],
                    weight: (item['Weight'] == '0') ? '' : item['Weight']
                })
            }
        }
    },
    async generateLeadCustomerInfo({ state }) {
        let leadparams = {

            FirstName: state.checkoutContact.firstName || 'unknown',

            LastName: state.checkoutContact.lastName || 'unknown',

            Email: state.owner.email.trim(),

            ZipCode: (state.checkoutContact.zip || state.owner.zip),

            StreetAddress: state.checkoutContact.address1,

            City: state.owner.city,

            Source: 'PetFirstCom',

            TPESource: null,

            MCID: state.mcid,

            CampaignId: state.campaignId
        }

        if(state.checkoutContact.pn !== null){
            leadparams['PhoneNumber'] = state.checkoutContact.pn.replace(/\D+/g, "");
        }else{
            leadparams['PhoneNumber'] = state.owner.pn.replace(/\D+/g, "");
        }

        if (state.owner.stateId == undefined) {

            for (var i = 0; i < state.states.length; i++) {
                if (state.states[i].abbreviation === state.owner.state) {
                    state.owner.stateId = state.states[i].id;
                    leadparams['StateId'] = state.states[i].id;
                }
            }
        } else {
            leadparams['StateId'] = state.owner.stateId
        }

        if (state.leadId !== undefined && state.leadId !== '') {
            leadparams['Id'] = state.leadId
        }

        if(state.reportNumber !== null){
            leadparams['ReportNumber'] = parseInt(state.reportNumber);
        }

        if(state.partner.id !== null){
            leadparams['GroupId'] = state.partner.id;
        }

        if(state.tpe !== ""){
            leadparams['TPESource'] = state.tpe;
        }

        if(state.utmSource !== null){
            leadparams['UtmSource'] = state.utmSource;
        }

        if(state.utmMedium !== null){
            leadparams['UtmMedium'] = state.utmMedium;
        }

        if(state.civilian !== null){
            leadparams['Civilian'] =  state.civilian;
        }

        if(state.uniformedService !== null){
            leadparams['UniformedService'] =  state.uniformedService;
        }

        return leadparams;

    },
    generateLeadCustomerActivity({ state }) {

        function getCurrentStep(state) {
            for (var key in state.steps) {
                if (state.steps[key].active) {
                    return key;
                }
            }
        }

        state.lastStep = getCurrentStep(state);

        let leadparams = {

            PetId: state.pets[0].id,

            Activity: state.lastStep,

            Source: 'PetFirstCom'
        }


        if (state.leadId !== undefined) {
            leadparams['LeadId'] = state.leadId
        }

        if (state.activityId !== undefined) {
            leadparams['Id'] = state.activityId
        }

        return leadparams;

    },
    generateLeadQuote({ state }) {

        let leadparams = {

            CoreQuoteId: state.policies[0] ? state.policies[0].plan.quoteId : '',

            PlanId: state.policies[0] ? state.policies[0].plan.legacyPlanId : '',

            EnrollmentCode: state.enrollmentCode,

            LeadSource: state.source,

            ReceiveSMS: state.isSms,

            EmailQuote: state.emailQuote,

            PaymentInterval: state.paymentInterval || 'Monthly',

            Deductible: state.policies[0] ? state.policies[0].plan.deductible : 1,

            QuoteDate: new Date(),
        }

        if (state.pets.length > 0) {
            leadparams['Pets'] = [];
            for (var i = 0; i < state.pets.length; i++) {
                leadparams['Pets'].push(state.pets[i].id);
            }
        }



        if (state.leadId !== undefined) {
            leadparams['LeadId'] = state.leadId
        }

        if (state.quoteId !== undefined) {
            leadparams['Id'] = state.quoteId
        }

        return leadparams;

    },
    generateLeadPet({ state }, { petIndex }) {
        let leadparams = {

            Id: state.pets[petIndex] ? state.pets[petIndex].id : '',

            Name: state.pets[petIndex] ? state.pets[petIndex].name : '',

            BreedId: state.pets[petIndex] ? state.pets[petIndex].breed : '',

            SpeciesId: state.pets[petIndex] ? state.pets[petIndex].species : '',

            Gender: state.pets[petIndex] ? state.pets[petIndex].gender == 1 ? "M" : "F" : '',

            Bday: state.pets[petIndex] ? moment().subtract('years', state.pets[petIndex].age) : '',

            WeightId: state.pets[petIndex] ? state.pets[petIndex].weight : '',

            PreviousCoverageId: state.pets[petIndex] ? state.pets[petIndex].providerId : null,

            PreviousCoverage: state.pets[petIndex] ? state.pets[petIndex].provider : ''
        }


        if (state.leadId !== undefined) {
            leadparams['LeadId'] = state.leadId
        }

        if (state.pets[petIndex].id !== undefined) {
            leadparams['Id'] = state.pets[petIndex].id
        }

        return leadparams;

    },

    async createOrUpdateLeadCustomerInfo({ state, dispatch }) {
        try {
            let data;

            await dispatch('generateLeadCustomerInfo')
                .then(async (response) => {
                    data = response;
                })
                .finally(async () => {
                    let response = await http.post('LeadCustomerInfo', data);
                    state.leadId = response.data.id;
                    VueCookies.set('leadId', state.leadId, '30d', '/', 'petfirst.com', false);
                    return response;
                });
        } catch (e) {

            console.log(e)
        }
    },

    async createOrUpdateLeadCustomerActivity({ state, dispatch }) {
        try {
            let data;
            await dispatch('generateLeadCustomerActivity')
                .then(response => {
                    data = response;
                })
                .finally(async () => {
                    let response = await http.post('LeadCustomerActivity', data);
                    state.activityId = response.data.id;
                    return response;
                });

        } catch (e) {
            console.log(e)
        }
    },

    async createOrUpdateLeadPet({ state, dispatch }, { petIndex }) {
        try {
            let data;
            await dispatch('generateLeadPet', { petIndex: petIndex })
                .then(response => {
                    data = response;
                })
                .finally(async () => {
                    let response = await http.post('LeadPet', data);

                    state.pets[petIndex].id = response.data.id;
                    return response;
                });
        } catch (e) {
            console.log(e)
        }
    },

    async createOrUpdateLeadPolicySold({ state }, policyId) {
        try {
            let data = { LeadId: state.leadId, CorePolicyId: policyId };
            let response = await http.post('LeadPolicy', data);
            return response;
        } catch (e) {
            console.log(e)
        }
    },
    async createOrUpdateLeadQuote({ state, dispatch }) {
        try {
            let data;
            await dispatch('generateLeadQuote')
                .then(response => {
                    data = response;
                })
                .finally(async () => {
                    let response = await http.post('LeadQuote', data);
                    state.quoteId = response.data.id;
                    return response;
                });
        } catch (e) {
            console.log(e)
        }
    },

    async saveQuoteByPhoneOrMail({ state, dispatch }, { email: email, phone: phone }) {
        try {
            let data = {
                Id: 0,
                LeadId: state.leadId,
                EmailId: email !== undefined ? email : null,
                // PhoneNumber: phone !== undefined ? phone : null,
                CreateDate: new Date(),
            }

            let response = await http.post(`Lead/${state.leadId}/SendQuote`, data);

            return response;
        } catch (e) {
            console.log(e)
        }
    },

    async getDecryptedLead({ state }, encryptedLeadId) {
        return await axios.get(`${apidomain}/Encryption/Decrypt/${encryptedLeadId}`);
    },

    async getDate({ state }) {
        var effectiveDate = state.effectiveDate;
        if (state.partner.effectiveDate !== undefined) {
            effectiveDate = state.partner.effectiveDate;
        }
        if (effectiveDate) {
            if (moment().isBefore(moment(effectiveDate))) {
                let calcDate = moment(effectiveDate).format("MM/DD/YYYY");
                state.effectiveDate = calcDate;
                state.accidentDate = calcDate;
                return calcDate;
            }
        }
        if (state.partner.billingOptions == 'payrollDeduct' || state.payOption == 'payrollDeduct') {
            //return the first day of the next month
            let calcDate = moment().add(1, 'M').startOf('month').format('MM/DD/YYYY');
            state.effectiveDate = calcDate;
            state.accidentDate = calcDate;
            return calcDate;
        } else {
            //get the next day
            let calcDate = moment().add(1, 'days').format('MM/DD/YYYY');
            state.effectiveDate = calcDate;
            state.accidentDate = calcDate;
            return calcDate;
        }
    },

    async getDatePlus14({ state }) {
        var effectiveDate = state.effectiveDate;
        if (state.partner.effectiveDate !== undefined) {
            effectiveDate = state.partner.effectiveDate;
        }
        if (effectiveDate) {
            if (moment().isBefore(moment(effectiveDate))) {
                let calcDate = moment(effectiveDate).add(14, 'days').format('MM/DD/YYYY');
                state.illnessDate = calcDate;
                return calcDate;
            }
        }
        if (state.partner.billingOptions == 'payrollDeduct' || state.payOption == 'payrollDeduct') {
            //return the 15th day of next month
            let calcDate = moment().add(1, 'M').startOf('month').add(14, 'days').format('MM/DD/YYYY');
            state.illnessDate = calcDate;
            return calcDate;
        }
        else {
            let calcDate = moment().add(15, 'days').format('MM/DD/YYYY');
            state.illnessDate = calcDate;
            return calcDate;
        }
    },

    waitForUserModal({state}, { heading, body, btnSubmit, btnCancel, fn, cancelFn, initFn, reload = false }) {
    
        state.modal.init = initFn;
        state.modal.show = true;
        state.modal.content.heading = heading;
        state.modal.content.body = body;
        state.modal.content.buttons.submit = btnSubmit;
        state.modal.content.buttons.cancel = btnCancel;
        state.modal.submit = fn;
        state.modal.cancel = cancelFn;
        if(reload){      
          state.modalKey = state.modalKey +1;
        }
        
      },

    async getProviders() {
        try {

            let response = await http.get('Policy/Providers');

            return response.data;
        } catch (e) {
            console.log(e);
        }
    },

    trackAnalyticsEvent({state},{eventType,ldo}){
        if(ldo.eventName === "quoteStart"){
            if(!state.hasSentQuoteStart){
                digitalData.eventTrack(eventType,ldo);
                state.hasSentQuoteStart = true;
            }
        }else{
            digitalData.eventTrack(eventType,ldo);
        }
    },

    analyticsExit({state, dispatch}){
        dispatch("trackAnalyticsEvent",{
            eventType: "quoteAbandon",
            ldo: {
                eventName: 'quoteAbandon',
                attributes: { 
                    application: 'MLPI',
                    quote:'Pet Insurance Quote',
                    stepName: state.currentPage
                }
            }
        });
    },

    updateAnalyticsEnrollmentCode({state},value){
        digitalData.user.attributes.enrollmentID = value;
    },

    async checkIfExistingCustomer({state,dispatch}){
        let data = {
            FirstName: state.existingCustomerSearch.firstName,
            LastName: state.existingCustomerSearch.lastName,
            MobilePhone: state.existingCustomerSearch.mobilePhone,
            EmailAddress: state.existingCustomerSearch.emailAddress
        };
        let response = await http.post("Customer/Search",data)
        .catch((err) => {
            return false;
        });
        
        if(response.status === 200){
            let customerData = response.data;
            
            if(customerData.firstName != null){
                state.checkoutContact.firstName = customerData.firstName;
                state.owner.firstName =  customerData.firstName;
            }

            if(customerData.lastName != null){
                state.checkoutContact.lastName = customerData.lastName;
                state.owner.lastName =  customerData.lastName;
            }

            if(customerData.emailAddress != null){
                state.owner.email = customerData.emailAddress.trim();
            }

            if(customerData.homePhone != null){
                state.checkoutContact.pn = customerData.homePhone;
            }

            if(customerData.workPhone != null){
                state.checkoutContact.pn = customerData.workPhone;
            }

            if(customerData.mobilePhone != null && customerData.mobilePhone !== ""){
                state.checkoutContact.pn = customerData.mobilePhone;
            }

            if(customerData.address != null){
                if(customerData.address.stateAbbreviation != null){
                    state.owner.state = customerData.address.stateAbbreviation;
                    state.checkoutContact.state = customerData.address.stateAbbreviation;
                }
                if(customerData.address.zipCode != null){
                    state.checkoutContact.zip = customerData.address.zipCode.substring(0, 5);
                    state.owner.zip = customerData.address.zipCode.substring(0,5);
                    dispatch("getUserState");
                }
                if(customerData.address.streetAddress != null){
                    state.checkoutContact.address1 = customerData.address.streetAddress;
                }
                if(customerData.address.streetAddress2 != null){
                    state.checkoutContact.address2 = customerData.address.streetAddress2;
                }
                if(customerData.address.cityName != null){
                    state.checkoutContact.city = customerData.address.cityName;
                }
            }
            state.isExistingCustomer = true;
            return true;
        }else{
            return false;
        }
    },

    async generateServerSideCookie({},rakutenCookieData){
        await http.post("Cookie",rakutenCookieData)
        .catch((err) => {
            return false;
        });
    },

    async setPartnerLogoByCDF({commit}, { partnerId: partnerId, reloadLogo: reloadLogo }){
        if(partnerId) {
            const cachedPartnerLogoSource = sessionStorage.getItem("partnerLogoSource");

            if (cachedPartnerLogoSource && !reloadLogo) {
                commit('setPartnerLogoSource', cachedPartnerLogoSource);
                return;
            }

            try {
                const response = await http.get(`EmployeeBenefits/Partners/${partnerId}/Logo`, {
                    headers: {
                        Accept: 'image/png, image/jpeg', // Accept both PNG and JPEG
                    },
                    responseType: 'blob', // Specify responseType as 'blob'
                });
            
                const reader = new FileReader();
                reader.onloadend = function() {
                    const base64data = reader.result;

                    commit('setPartnerLogoSource', base64data);
                    sessionStorage.setItem('partnerLogoSource', base64data) ;

                    commit("setReloadLogo", false);
                }
                reader.readAsDataURL(response.data);

                return reader.result;
            } catch (error) {
                console.error('Error fetching image', error);
                commit("setPartnerLogoSource","");
            }
        } else {
            commit("setPartnerLogoSource","");
        }
    },

    arrayBufferToBase64(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        for (let i = 0; i < bytes.byteLength; i ++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    },

    setPartnerLogoByPartnerId({commit,state},partnerId){
        if(partnerId == 3582 || partnerId == 3846 || partnerId == 6381){
            state.partnerId = '36805';
            commit("setPartnerLogoSource",require('../assets/images/Target-Logo.png'));
        }
    },

    resetSelectedPlans({state}){
        state.selectedPolicies = [];
    },

    async getRenewalOptions({ state }, { policyId: policyId, token: token, optionId: optionId }) {
        try {
            let apiResponse = await http.post('RenewalOptions', { 
                policyId: policyId,
                token: token,
                optionId: optionId
            });

            let response = apiResponse.data;
            if(response && response.leadGuid) state.leadId = response.leadGuid;

            return response;
        } catch (e) {
            console.log(e);

            var ldo = {
                eventName: 'quoteError',
                attributes: { 
                application: 'MLPI',
                    form:'Pet Insurance Renewal Quote',
                    errorMessage: 'Link Error'
                }
            }
            digitalData.eventTrack( 'quoteError',ldo);
        }
    },

    async saveRenewalOption({ state }, { policyId: policyId, optionId: optionId, payload: payload }) {
        try {
            let apiResponse = await http.post(`SaveRenewalOption/${policyId}/${optionId}`, payload);
            let response = apiResponse.data;

            return response;
        } catch (e) {
            console.log(e);
        }
    },

    async validateEmployeeInfo({ state }, { payload: payload, requireToken: requireToken, token: token}) {
        try {
            let apiResponse = requireToken 
                ? await http.post('ValidateEmployeeInfoToken', payload, { headers: { 'Authorization': `${token}` } })
                : await http.post(`ValidateEmployeeInfo`, payload);
            let response = apiResponse.data;

            return response;
        } catch (e) {
            console.log(e);
        }
    },

    async getDefaultAdminConfig({ commit, state, dispatch }) {
        try {
            const dynamicContent = await dispatch("getAdminConfigUpdatedDate", { 
                payload: { partnerId: undefined, enrollmentCode: undefined, urlString: 'pet' }
            });
            
            if(!state.defaultDynamicContent?.UpdatedDate || state.defaultDynamicContent?.UpdatedDate !== dynamicContent.UpdatedDate) {

                let apiResponse = await http.get(`GetQuoteFunnelAdminConfig?urlString=pet`);
                let response = apiResponse.data;

                if(response.Data) {
                    sessionStorage.removeItem('step1DefaultSideImgSource');
                    sessionStorage.removeItem('step2DefaultSideImgSource');
                    sessionStorage.removeItem('step3DefaultSideImgSource');
                    sessionStorage.removeItem('step4DefaultSideImgSource');

                    if (response.Data.DynamicJson) {
                        response.Data.DynamicJson = JSON.parse(response.Data.DynamicJson);
                    }

                    if (!response.Data.DynamicJson.hasOwnProperty('nyRestrictions')) {
                        response.Data.DynamicJson.nyRestrictions = false;
                    }

                    if (!response.Data.DynamicJson.hasOwnProperty('federalFunctionality')) {
                        response.Data.DynamicJson.federalFunctionality = false;
                    }

                    if (!response.Data.DynamicJson.hasOwnProperty('groupKeyIdValidation')) {
                        response.Data.DynamicJson.groupKeyIdValidation = false;
                    }

                    if (!response.Data.DynamicJson.hasOwnProperty('retrieveFunctionality')) {
                        response.Data.DynamicJson.retrieveFunctionality = false;
                    }

                    if (!response.Data.DynamicJson.hasOwnProperty('retrieveFunctionality')) {
                        response.Data.DynamicJson.retrieveFunctionality = false;
                    }
                    
                    commit("setDefaultDynamicContent", response.Data);
                }

                return response;
            }
        } catch (e) {
            console.log(e);
        }
    },

    async getAdminConfig({ commit, state, dispatch }, { payload: payload }) {
        let apiUrl = "";

        if (payload.urlString !== undefined) apiUrl = `GetQuoteFunnelAdminConfig?urlString=${payload.urlString}`;
        if (payload.enrollmentCode !== undefined) apiUrl = `GetQuoteFunnelAdminConfig?enrollmentCode=${payload.enrollmentCode}`;
        if (payload.partnerId !== undefined) apiUrl = `GetQuoteFunnelAdminConfig?partnerId=${payload.partnerId}`;

        try {
            const dynamicContent = await dispatch("getAdminConfigUpdatedDate", { 
                payload: { partnerId: payload.partnerId, enrollmentCode: payload.enrollmentCode, urlString: payload.urlString }
            });

            if(!state.dynamicContent?.UpdatedDate || state.dynamicContent?.UpdatedDate !== dynamicContent.UpdatedDate) {

                let apiResponse = await http.get(apiUrl);
                let response = apiResponse.data;

                if(response.Data) {
                    if (response.Data.DynamicJson) {
                        response.Data.DynamicJson = JSON.parse(response.Data.DynamicJson);
                    }

                    if(response.Data.IsPublished) {
                        const dynamicContentKey = payload.partnerId || payload.enrollmentCode || payload.urlString;
                        if(state.dynamicContentKey !== dynamicContentKey) {
                            state.reloadLogo = true;

                            commit("setDynamicContentKey", dynamicContentKey);
                            sessionStorage.getItem("partnerLogoSource");
                            sessionStorage.removeItem('step1SideImgSource');
                            sessionStorage.removeItem('step2SideImgSource');
                            sessionStorage.removeItem('step3SideImgSource');
                            sessionStorage.removeItem('step4SideImgSource');
                        }
                        commit("setDynamicContent", response.Data);
    
                        if(response.Data.ImageName && response.Data.DynamicJson.companyLogoMaxWidth){
                            await dispatch("setDynamicPartnerLogo", { payload: payload, reloadLogo: state.reloadLogo });
                        } else {
                            if(state.partner && state.partner.displayInQuoteFunnel && payload.partnerId !== undefined) dispatch('setPartnerLogoByCDF', { partnerId: state.partner.cdfGroupNumber, reloadLogo: state.reloadLogo });
                        }
                    } else {
                        commit("clearDynamicContent");
                        sessionStorage.getItem("partnerLogoSource");
                        sessionStorage.removeItem('step1SideImgSource');
                        sessionStorage.removeItem('step2SideImgSource');
                        sessionStorage.removeItem('step3SideImgSource');
                        sessionStorage.removeItem('step4SideImgSource');

                        let intervalId = setInterval(() => {
                            if (state.partner && state.partner.displayInQuoteFunnel && payload.partnerId !== undefined) {
                                dispatch('setPartnerLogoByCDF', { partnerId: state.partner.cdfGroupNumber, reloadLogo: true });
                                clearInterval(intervalId);
                            }
                        }, 1000);
                        setTimeout(() => clearInterval(intervalId), 10000);
                    }

                } else {
                    commit("clearDynamicContent");
                    sessionStorage.getItem("partnerLogoSource");
                    sessionStorage.removeItem('step1SideImgSource');
                    sessionStorage.removeItem('step2SideImgSource');
                    sessionStorage.removeItem('step3SideImgSource');
                    sessionStorage.removeItem('step4SideImgSource');

                    let intervalId = setInterval(() => {
                        if (state.partner && state.partner.displayInQuoteFunnel && payload.partnerId !== undefined) {
                            dispatch('setPartnerLogoByCDF', { partnerId: state.partner.cdfGroupNumber, reloadLogo: true });
                            clearInterval(intervalId);
                        }
                    }, 1000);
                    setTimeout(() => clearInterval(intervalId), 10000);
                }

                return response;
            }
        } catch (e) {
            console.log(e);
        }
    },

    async setDynamicPartnerLogo({commit}, { payload: payload, reloadLogo: reloadLogo}){
        const cachedPartnerLogoSource = sessionStorage.getItem("partnerLogoSource");

        if (cachedPartnerLogoSource && !reloadLogo) {
            commit('setPartnerLogoSource', cachedPartnerLogoSource);
            return;
        }

        let apiUrl = "";

        if (payload.urlString !== null && payload.urlString !== undefined) apiUrl = `GetPartnerLogo?urlString=${payload.urlString}`;
        if (payload.enrollmentCode !== null && payload.enrollmentCode !== undefined) apiUrl = `GetPartnerLogo?enrollmentCode=${payload.enrollmentCode}`;
        if (payload.partnerId !== null && payload.partnerId !== undefined) apiUrl = `GetPartnerLogo?partnerId=${payload.partnerId}`;

        try {
            const response = await http.get(apiUrl, {
                headers: {
                    Accept: 'image/png, image/jpeg', 
                },
                responseType: 'blob', 
            });
        
            const reader = new FileReader();
            reader.onloadend = function() {
                const base64data = reader.result;

                commit('setPartnerLogoSource', base64data);
                sessionStorage.setItem('partnerLogoSource', base64data) ;

                commit("setReloadLogo", false);
            }
            reader.readAsDataURL(response.data);

            return reader.result;
        } catch (error) {
            console.error('Error fetching image', error);
            commit("setPartnerLogoSource","");
        }
    },

    async getSidebarImage({ commit, state }, { payload: payload, isDefaultContent: isDefaultContent }){
        try {
            const apiResp = await http.get(`GetSidebarImg?id=${payload.id}&imageName=${payload.imageName}`, {
                headers: {
                    Accept: 'image/png, image/jpeg',
                },
                responseType: 'blob',
            });

            const reader = new FileReader();
            reader.onloadend = function() {
                const base64data = reader.result;

                switch (payload.seqNum) {
                    case 0:
                        commit('setStep1SideImgSource', base64data);
                        isDefaultContent 
                            ? sessionStorage.setItem('step1DefaultSideImgSource', base64data) 
                            : sessionStorage.setItem('step1SideImgSource', base64data);
                        break;
                    case 1:
                        commit('setStep2SideImgSource', base64data);
                        isDefaultContent 
                            ? sessionStorage.setItem('step2DefaultSideImgSource', base64data) 
                            : sessionStorage.setItem('step2SideImgSource', base64data);
                        break;
                    case 2:
                        commit('setStep3SideImgSource', base64data);
                        isDefaultContent 
                            ? sessionStorage.setItem('step3DefaultSideImgSource', base64data) 
                            : sessionStorage.setItem('step3SideImgSource', base64data);
                        break;
                    case 3:
                        commit('setStep4SideImgSource', base64data);
                        isDefaultContent 
                            ? sessionStorage.setItem('step4DefaultSideImgSource', base64data) 
                            : sessionStorage.setItem('step4SideImgSource', base64data);
                        break;
                }
            }
            reader.readAsDataURL(apiResp.data);

            return reader.result;
        } catch (e) {
            console.log(e);
        }
    },
    async getUserBillingState({ state }, zip) {
        let response = await http.get('Address?zipCode=' + zip);
        
        if (response.status === 200) {

            if(response?.data?.zipCode) {
                return { 
                    stateAbbreviation: response.data.stateAbbreviation,
                    cityName: response.data.cityName
                }
            }

            return undefined;
        }

        return undefined;
    },
    async setPetsWithWellness({ state }, riderId) {
        state.petsWithWellness[state.currentPetIndex] = riderId;
    },
    async getCustomizedFastQuotes({state},{ policies }){
        let payload = [];

       for(const [index, policy] of policies.entries()){
            let planId = state.limitsWithFilings.find(p => p.limit === policy.planDetails.limit).planId;
            let totalMultiPolicyDiscount =  state.isFamilyPlan ? 1 : state.multiPetDiscounts[index];

            let data = {
                "emailAddress": state.owner.email,
                "address": {
                    "streetAddress": "",
                    "streetAddress2": "",
                    "cityName": "",
                    "stateAbbreviation": state.owner.state,
                    "zipCode": state.owner.zip
                },
                "pets": [],
                "paymentFrequency": "Monthly",
                "legacyPlanId": planId,
                "discountIds": state.discounts.selection,
                "enrollmentCode": state.enrollmentCode,
                "termLimit": policy.limit,
                "deductible": policy.deductible,
                "reimbursement": policy.reimbursement,
                "riderId":  policy.riderId,
                "TotalMultiPolicyDiscount":  totalMultiPolicyDiscount,
            };

            if(state.isExistingCustomer){
                data["TotalMultiPolicyDiscount"] = 3;
            }

            if ((state.partner.name != null && state.partner.billingOptions === "payrollDeduct") || state.payOption === "payrollDeduct") {
                data['isPayrollDeduct'] = true;
            } else {
                data['isPayrollDeduct'] = false;
            }
    
            if (state.effectiveDate !== undefined) {
                data['EffectiveDate'] = state.effectiveDate;
            }

            if(!state.isFamilyPlan){
                let p = state.pets[index];
                let pet = {
                    "petName": p.name,
                    "speciesId": parseInt(p.species),
                    "breedId": parseInt(p.breed),
                    "weight": p.weight,
                    "age": parseInt(p.age),
                    "gender": parseInt(p.gender),
                    "isMixedBreed": p.mixed
                }
                data.pets.push(pet);
            } else { 
                state.pets.forEach(p => {
                    let pet = {
                        "petName": p.name,
                        "speciesId": parseInt(p.species),
                        "breedId": parseInt(p.breed),
                        "weight": p.weight,
                        "age": parseInt(p.age),
                        "gender": parseInt(p.gender),
                        "isMixedBreed": p.mixed
                    }
                    data.pets.push(pet);
                });
            }

            payload.push(data);

            if(state.isFamilyPlan) break;
        };

        try {
            let response = await http.post('FastQuotes', payload);
            return response.data;
        } catch (e) {
            //TODO: DISPLAY ERROR
            console.error(e);
        }
    },

    async getAdminConfigUpdatedDate({ commit, state, dispatch }, { payload: payload }) {
        let apiUrl = "";

        if (payload.urlString !== undefined) apiUrl = `GetQuoteFunnelAdminConfig?urlString=${payload.urlString}&getImgConfig=false`;
        if (payload.enrollmentCode !== undefined) apiUrl = `GetQuoteFunnelAdminConfig?enrollmentCode=${payload.enrollmentCode}&getImgConfig=false`;
        if (payload.partnerId !== undefined) apiUrl = `GetQuoteFunnelAdminConfig?partnerId=${payload.partnerId}&getImgConfig=false`;

        try {
            let apiResponse = await http.get(apiUrl);
            let response = apiResponse.data;

            if(response.Data) return response.Data;

            return undefined;
        } catch (e) {
            console.log(e);
        }
    },
}

const store = createStore(Vuex.Store, {
    state: state,
    defaultState: getDefaultState(),
    mutations: mutations,
    actions: actions,
    getters: getters,
    plugins: [vuexLocal.plugin]
})

export default store;
