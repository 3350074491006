import Vue from 'vue'
import Router from 'vue-router'

import temp from "@/pages/temp.vue";
import store from "@/store/index";
import { navGuard } from "@/guards/navGuard";

Vue.use(Router)
const DEFAULT_TITLE = 'MetLife Pet Insurance: Quote Funnel';
const router = new Router({
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        { path: '/retrieve', params: true, query: (route) => ({ query: route.query }), meta: { title: 'Retrieve Previous Quote  - MetLife Pet Insurance: Quote Funnel'},  beforeEnter: navGuard },
        { path: '/quotes', params: true, query: (route) => ({ query: route.query }), meta: { title: 'Plan Selection  - MetLife Pet Insurance: Quote Funnel'}, beforeEnter: navGuard },
        { path: '/billing', params: true, query: (route) => ({ query: route.query }), meta: { title: 'Contact and Billing Information - MetLife Pet Insurance: Quote Funnel'}, beforeEnter: navGuard },
        { path: '/payment', params: true, query: (route) => ({ query: route.query }), meta: { title: 'Payment - MetLife Pet Insurance: Quote Funnel'}, beforeEnter: navGuard },
        { path: '/summary', params: true, query: (route) => ({ query: route.query }) , meta: { title: 'Summary - MetLife Pet Insurance: Quote Funnel'}, beforeEnter: navGuard },
        { path: '/Policy/:id/renewal-options', params: true, query: (route) => ({ query: route.query }) , meta: { title: 'Renewal - MetLife Pet Insurance: Quote Funnel'}, beforeEnter: navGuard },
        { path: '/temp', component: temp, params: true, query: (route) => ({ query: route.query }), meta: { title: 'Theme - MetLife Pet Insurance: Quote Funnel'} },
        { path: '/:name', params: true, query: (route) => ({ query: route.query }), meta: { title: 'Add Your Pet for a Quote - MetLife Pet Insurance: Quote Funnel' }, beforeEnter: navGuard },
        { path: '/get-a-quote/pet/', redirect: '/pet', params: true, query: (route) => ({ query: route.query }) , meta: { title: 'Get a Quote - MetLife Pet Insurance: Quote Funnel'}  },
        { path: '/', redirect: '/pet'}
    ]
});

/**
 * Prevent the user from going to a step without 
 * completing the step(s) required.
 * If the user have the required steps just continue
 * but preserving the query params if not, go back
 * to the step that needs to be completed.
 */
router.beforeEach((to, from, next) => {
    const path = to.path.replace(/\//g, "").replace('get-a-quote', '');
    const fromPath = from.path.replace(/\//g, "");
    const toPath = to.path.replace(/\//g, "");
    const queryParams = !hasQueryParams(to) && hasQueryParams(from) ? from.query : to.query;

    if(store.state.steps.summary?.complete) store.reset();

    const project = store.state.project;
    const currentPage = store.state.currentPage;
    const steps = store.state.steps;
    const pet = store.state.steps.pet;
    const pricing = store.state.steps.pricing;
    const billing = store.state.steps.billing;
    const payment = store.state.steps.payment;
    const summary = store.state.steps.summary;

    // Deactive steps to set the correct ones on the switch
    Object.keys(steps).map((key) => steps[key].active = false);

    switch (path) {
        case "quotes":
            if(pet.complete) {
                pricing.active = true;
                next({ query: queryParams });
            } else {
                pet.active = true;
                next({ path: "/pet", query: queryParams });
            }
            break;
            
        case "billing":
            if(pet.complete && pricing.complete) {
                activateBilling(store.state);
                next({ query: queryParams });
            } else if (pet.complete && !pricing.complete) {
                pricing.active = true;
                next({ path: "/quotes", query: queryParams });
            } else {
                pet.active = true;
                next({ path: "/pet", query: queryParams });
            }
            break;

        case "payment": 
            if(pet.complete && pricing.complete && billing.complete) {
                payment.active = true;
                next({ query: queryParams });
            } else if (pet.complete && !pricing.complete && !billing.complete) {
                pricing.active = true;
                next({ path: "/quotes", query: queryParams });
            } else if (pet.complete && pricing.complete && !billing.complete) {
                activateBilling(store.state);
                next({ path: "/billing", query: queryParams });
            } else {
                pet.active = true;
                next({ path: "/pet", query: queryParams });
            }
            break;

        case "summary":
            if(fromPath === "payment" && toPath === "summary") {
                summary.active = true;
                next({ query: queryParams });
            } else if (
                (fromPath === "billing" && toPath === "summary") && 
                project === "challenger" && currentPage === "Confirmation Page"
            ) {
                summary.active = true;
                next({ query: queryParams });
            } else if (
                (fromPath === "" && toPath === "summary") &&
                project === "challenger" && currentPage === "Confirmation Page"
            ) {
                // Set Payment Request List to null because 
                // of Token in SubmitPayment
                store.state.paymentRequestList = null; 

                activateBilling(store.state);
                next({ path: "/billing", query: queryParams });
            } else {
                pet.active = true;
                store.reset();
                next({ path: "/pet", query: {} });
            }
            break;

        default:
            pet.active = true;
            next({ query: queryParams })
            break;
    }
})

router.afterEach((to,from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

function hasQueryParams(route) {
    return !!Object.keys(route.query).length
}

const activateBilling = (state) => {
    const project = state.project;
    const steps = state.steps;

    if (project === "legacy") { 
        steps.billing.active = true;
    } else {
        steps.billing.active = true;
        steps.payment.active = true;
    }
}

export default router;