import store from "@/store/index";

export const navGuard = (to, from, next) => {
    let path = to.path.replace(/\//g, "");

    if(to.params.id) {
        const regex = /^\/Policy\/\d+\/renewal-options$/; 
        const match = to.path.match(regex); 

        if (match) path = 'renewal'
    }

    const queryParams = to.query;
    const state = store.state;

    let project = getProjectName(queryParams, state);
    state.project = project;

    getDynamicContent(queryParams, path, state);

    const page = getPageName(path, project);
    const layoutName = capitalize(project);

    // Load page and layout base on project 
    Promise.all([
        import(`@/pages/${project}/${page}.vue`),
        import(`@/layouts/${layoutName}.vue`)
    ]).then(([component, layout]) => {
        to.matched[0].components.default = component.default;
        to.meta.layout = layout.default;
        to.meta.layoutName = layoutName;

        next();
     //comment this if page halts   
     }).catch(() => {
        const defaultPage = getPageName(path, "challenger");
        // Load challenger project if something brakes in the import 
        Promise.all([
            import(`@/pages/challenger/${defaultPage}.vue`), 
            import(`@/layouts/Challenger.vue`)
        ]).then(([component, layout]) => {
            console.log("Loading Challenger components due to Error *******************************************");
            to.matched[0].components.default = component.default;
            to.meta.layout = layout.default;
            to.meta.layoutName = "Challenger";
    
            next();
        })
        //comment this if page halts ENDS

     });
};

const capitalize = (str) => {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}

/**
 * Get the project name base on conditions. 
 * For the challenger project we are basing that desition on the -
 * partnerId but it could be multiple in the future (04/12/2023).
 * Project name must be in lowercase.
 * @param {string} queryParam - Current router to query parameter.
 * @param {string} state - Vuex store object.
 * @returns {string} The name of the project.
 */
const getProjectName = (query, state) => {
    let projectName = "challenger"; // default project

    // Set project name base on enrollment code.
    // Condition retire base on the global implementation of the challenger
    // if(query.enrollment_code !== undefined) {
    //     const code = query.enrollment_code;
    //     if(code === "99-99-00-9876" || code === "99-99-99-0334" 
    //         || code === "99-99-99-0333" || code === "99-99-00-6555"){
    //             projectName = "challenger";
    //     }
    // }

    //  new condition for a new future project.
    // if(newCondition) projectName = "curiosity";

    // Condition to force the display of one project.
    // Condition retire base on the global implementation of the challenger
    // if(query.project) {
    //     switch (query.project) {
    //         case "1": 
    //             projectName = "legacy"
    //             break;
    //         case "2": 
    //             projectName = "challenger"
    //             break;
    //         default:
    //             projectName = "legacy"
    //             break;
    //     }
    // }

    // Allow legacy on Dev and QA for testing
    if(query.project == "1" && String(process.env.VUE_APP_API_URL) !== 'https://quote.metlifepetinsurance.com') projectName = "legacy";

    return projectName;
}

/**
 * Get the page name base on the path and the project if need it.
 * For the challenger first three pages are the same but the -
 * fourth page in the challenger changes to order insted of 
 * Payment (use it in legacy). (04/13/2023).
 * This time we are just doing one basic condition if it is 
 * challenger but it could have multiples depends on the amount 
 * of project and how it reuses pages depending on the path.
 * @param {string} path - Current url path without the '/'
 * @param {string} project - Current project (legacy, challenger, ...)
 * @returns {string} The name of the page.
 */
const getPageName = (path, project) => {
    switch (path) {
        case "pet": 
            return "RenderEngine";
        case "quotes":
            return "PricingPage";
        case "billing":
            return "CustomerDetails";
        case "payment":
            return project === "challenger" ? "Order" : "Payment";
        case "summary":
            return "Order";
        case "renewal":
            return "Renewal";
        case "retrieve":
            return "RetrieveQuote";
        default:
            return "RenderEngine";
    }
};

const getDynamicContent = (query, path, state) => {
    store.dispatch("getDefaultAdminConfig");

    if(query.partnerId !== undefined && String(query.partnerId) !== "999999") {
        store.dispatch("getAdminConfig", {
            payload: { partnerId: query.partnerId, enrollmentCode: undefined, urlString: undefined }
        });
        state.dynamicUrl = null;
        return;
    }

    if(query.enrollment_code !== undefined && String(query.enrollment_code) !== '99-99-00-9998' && String(query.enrollment_code) !== '99-99-00-0100' && String(query.enrollment_code) !== '99-99-01-6000' && String(query.enrollment_code) !== '99-99-05-0100') {
        store.dispatch("getAdminConfig", {
            payload: { partnerId: undefined, enrollmentCode: query.enrollment_code, urlString: undefined }
        });
        state.dynamicUrl = null;
        return;
    }

    if(path !== 'pet' && path !== 'quotes' && path !== 'billing' && path !== 'payment' && path !== 'summary' && path !== 'renewal' && path !== 'retrieve') {
        const urlString = path;
        
        store.dispatch("getAdminConfig", {
            payload: { partnerId: undefined, enrollmentCode: undefined, urlString: urlString }
        });

        state.dynamicUrl = urlString;
        return;
    }

    if((path === 'quotes' || path === 'billing' || path === 'payment' || path === 'summary' || path === 'renewal' && path === 'retrieve') && state.dynamicUrl !== null) {
        store.dispatch("getAdminConfig", {
            payload: { partnerId: undefined, enrollmentCode: undefined, urlString: state.dynamicUrl }
        });
        return;
    }
    
    if((path === 'pet' || path === 'quotes' || path === 'billing' || path === 'payment' || path === 'summary' || path === 'renewal' && path === 'retrieve') 
        && query.partnerId === undefined 
        && (query.enrollment_code === undefined || String(query.enrollment_code) === '99-99-00-9998' || String(query.enrollment_code) === '99-99-00-0100')
    ) {

        store.commit('clearDynamicContent');
        sessionStorage.getItem("partnerLogoSource");
        sessionStorage.removeItem('step1SideImgSource');
        sessionStorage.removeItem('step2SideImgSource');
        sessionStorage.removeItem('step3SideImgSource');
        sessionStorage.removeItem('step4SideImgSource');
        
        state.dynamicUrl = null;
        return;
    }
};


