<template>
    <div :id="this.$route.meta.layoutName" v-if="this.$route.meta.layout">
        <component :is="this.$route.meta.layout || 'div'">
            <router-view />
        </component>
    </div>
    <div id="app" v-else>
        <v-app>
            <v-main>
                <router-view />
            </v-main>
        </v-app>
    </div>
    
</template>
<script>
    export default {
        data: () => ({
            isProduction: false,
            showSaveQuoteDialog: false
        }),
        created(){
            if(process.env.NODE_ENV === "production"){
                this.isProduction = true;
            }
        },
        beforeMount() {
            const project = this.$store.getters.getProject;
            const theme = this.$vuetify.theme;

            // string must be in lowercase. (check navGuard.js -
            // function getProjectName comment).
            if(project === "legacy") theme.light = true;

            if(project === "challenger") theme.dark = true;
        },
    }
</script>

<style>
    @import 'assets/css/styles.css';
</style>