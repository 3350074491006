import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

//icons
import petPawIcon from "../components/shared/VuetifyIcons/petPaw.vue";
import coverageIcon from "../components/shared/VuetifyIcons/coverage.vue";
import coverageCheck from "../components/shared/VuetifyIcons/coverage-check.vue";
import coverageTimes from "../components/shared/VuetifyIcons/coverage-times.vue";
import discountIcon from "../components/shared/VuetifyIcons/discount-icon.vue";
import sendQuoteDog from "../components/shared/VuetifyIcons/send-quote-dog.vue";
import sendQuoteBone from "../components/shared/VuetifyIcons/send-quote-bone.vue";
import boardCheck from  "../components/shared/VuetifyIcons/board-check.vue";
import dialogQuestion from  "../components/shared/VuetifyIcons/dialog-question.vue";
import dialogExclamation from "../components/shared/VuetifyIcons/dialog-exclamation.vue";
import metlifeHeart from  "../components/shared/VuetifyIcons/metlife-heart.vue";
import snoopy1 from  "../components/shared/VuetifyIcons/snoopy1.vue";
import snoopy2 from  "../components/shared/VuetifyIcons/snoopy2.vue";
import snoopy3 from  "../components/shared/VuetifyIcons/snoopy3.vue";
import snoopyDog from  "../components/shared/VuetifyIcons/snoopy-dog-icon.vue";
import snoopyCat from  "../components/shared/VuetifyIcons/snoopy-cat-icon.vue";
import medal from  "../components/shared/VuetifyIcons/medal.vue";
import shield from  "../components/shared/VuetifyIcons/shield.vue";
import cardVisa from  "../components/shared/VuetifyIcons/card-visa.vue";
import cardMastercard from  "../components/shared/VuetifyIcons/card-mastercard.vue";
import cardAmex from  "../components/shared/VuetifyIcons/card-amex.vue";
import cardDiscovery from  "../components/shared/VuetifyIcons/card-discovery.vue";
import plusCircle from  "../components/shared/VuetifyIcons/plus-circle.vue";

Vue.use(Vuetify);

const vuetifyIcons = {
    petPawIcon: {
        component: petPawIcon,
    },
    coverageIcon: {
        component: coverageIcon,
    },
    coverageCheck: {
        component: coverageCheck,
    },
    coverageTimes: {
        component: coverageTimes,
    },
    discountIcon: {
        component: discountIcon,
    },
    sendQuoteDog: {
        component: sendQuoteDog,
    },
    sendQuoteBone: {
        component: sendQuoteBone,
    },
    boardCheck: {
        component: boardCheck,
    },
    dialogQuestion: {
        component: dialogQuestion,
    },
    dialogExclamation: {
        component: dialogExclamation,
    },
    metlifeHeart: {
        component: metlifeHeart,
    },
    snoopy1: {
        component: snoopy1,
    },
    snoopy2: {
        component: snoopy2,
    },
    snoopy3: {
        component: snoopy3,
    },
    snoopyDog: {
        component: snoopyDog,
    },
    snoopyCat: {
        component: snoopyCat,
    },
    medal: {
        component: medal,
    },
    shield: {
        component: shield,
    },
    cardVisa: {
        component: cardVisa,
    },
    cardMastercard: {
        component: cardMastercard,
    },
    cardAmex: {
        component: cardAmex,
    },
    cardDiscovery: {
        component: cardDiscovery,
    },
    plusCircle: {
        component: plusCircle,
    }
}
export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
                light: {
                    primary: '#007ABC',
                    secondary: '#6FA123',
                    anchor: '#0090DA',
                    accent: '#82B1FF',
                    error: '#DD2224',
                    info: '#0090DA',
                    success: '#A4CE4E',
                    warning: '#FFC600',
                    
                    ml_blue: '#007ABC',
                    ml_primaryblue: '#007ABC',
                    ml_lightblue: '#0090DA',
                    ml_darkblue: '#0061A0',
                    ml_darkergray: '#333333',
                    ml_green: '#A4CE4E',
                    ml_secondarygreen: '#6FA123',
                    ml_secondarygreenselect:  '#5C782D',
                    ml_secondarydarkgreen: '#528320',
                    ml_darkgray: '#75787B',
                    ml_mediumgray: '#A7A8AA',
                    ml_lightgray: '#F2F2F2',
                    ml_gray: '#D9D9D9',
                    ml_red: '#DD2224',
                    ml_berry: '#DB0A5B',
                    ml_purple: '#5F259F',
                    ml_yellow: '#FFC600',
                    ml_teal: '#00ACA0',

                    white: '#ffffff',
                    black: '#000000',
                    ml_additional_blue_for_DDL: '#BADDF5',
                },
                dark: {
                    primary: '#007ABC',
                    secondary: '#6FA123',
                    tertiary: '#333333', 
                    anchor: '#0090DA',
                    accent: '#82B1FF',
                    error: '#DD2224',
                    info: '#0090DA',
                    success: '#A4CE4E',
                    warning: '#FFC600',
                    
                    ml_blue: '#007ABC',
                    ml_primaryblue: '#007ABC',
                    ml_lightblue: '#0090DA',
                    ml_darkblue: '#0061A0',
                    ml_darkergray: '#333333',
                    ml_green: '#A4CE4E',
                    ml_secondarygreen: '#6FA123',
                    ml_secondarygreenselect:  '#5C782D',
                    ml_secondarydarkgreen: '#528320',
                    ml_darkgray: '#75787B',
                    ml_mediumgray: '#A7A8AA',
                    ml_lightgray: '#F2F2F2',
                    ml_gray: '#D9D9D9',
                    ml_red: '#DD2224',
                    ml_berry: '#DB0A5B',
                    ml_purple: '#5F259F',
                    ml_yellow: '#FFC600',
                    ml_teal: '#00ACA0',

                    challenger_lightgray: '#F0F0F0',
                
                    white: '#ffffff',
                    black: '#000000',
                    ml_additional_blue_for_DDL: '#BADDF5',
                }
            },
        }, 
        icons: {
            values: vuetifyIcons,
        }
});
